import React from 'react';
import { OrganizationsStatsCard } from 'components/OrganizationsStatsCard';

import styles from './styles.module.scss';

interface Props {
    topTitle: string;
    topNumber?: string;
    bottomTitle?: string;
    bottomNumber?: string;
    allowCustomDisplay?: boolean;
}

export const VotingTokenItem = ({
    topTitle,
    topNumber,
    bottomTitle,
    bottomNumber,
    allowCustomDisplay = false,
}: Props) => (
    <div className={styles.organizationsStatsCardContainer}>
        <div className={styles.organizationsStatsCardWrapper}>
            <OrganizationsStatsCard
                totalTitle={topTitle}
                statsData={{
                    total: topNumber,
                    data: [],
                }}
                allowCustomDisplay={allowCustomDisplay}
            />
            <hr />
            <OrganizationsStatsCard
                totalTitle={bottomTitle}
                statsData={{
                    total: bottomNumber,
                    data: [],
                }}
                allowCustomDisplay={allowCustomDisplay}
            />
        </div>
    </div>
);
