import { uniqBy } from 'lodash';
import { defaultOrganizationPlaceholderImage } from 'assets';
import { tableDataSort } from 'helpers';
import { EXECUTIVE_SUMMARY_URL } from 'constants/organizationTabs';

import { History } from 'history';

import { IOrganizationSearchItems, ISearchAutocompleteItems } from 'types/interfaces';

export const createOrganizationsAutocompleteData = (
    data: IOrganizationSearchItems[],
    history: History,
    sortingActive = false,
): ISearchAutocompleteItems[] => {
    const newData = data.map(
        ({ id, organizationId, organizationName, name, organizationImage }: IOrganizationSearchItems) => ({
            id: id || organizationId,
            name: organizationName || name,
            logo: organizationImage || defaultOrganizationPlaceholderImage,
            onClick: () => history.push(`/organization/${id || organizationId}/organization_data/${EXECUTIVE_SUMMARY_URL}`),
        }),
    );

    return sortingActive ? newData : tableDataSort(uniqBy(newData, 'id'), { paramName: 'name', descending: false });
};
