import React, { FC, ReactElement, memo } from 'react';

import { Button } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { IHeaderRectangularButtonProps } from 'types/interfaces';

export const HeaderRectangularButton: FC<IHeaderRectangularButtonProps> = memo(
    ({ onClick, title }: IHeaderRectangularButtonProps): ReactElement => (
        <div className={styles.wrapper}>
            <Button
                className={styles.button}
                disableRipple
                onClick={onClick}
            >
                {title}
            </Button>
        </div>
    ),
);
