import {
    COMMON_TABLE_FIELD_TYPES,
    ICommonTableHeader,
    ICommonTableHeaderSettings,
} from '../../../../../../CommonTable/types';
import {
    IOrganizationTopDelegate,
    ITopDelegatesEnsName,
    ITopDelegatesGScore,
    ITopDelegatesNumberOfDelegators,
    ITopDelegatesRank,
    ITopDelegatesTotalVotes,
    ITopDelegatesVotes1Year,
    ITopDelegatesVotes3Months,
    ITopDelegatesVotes6Months,
    ITopDelegatesVotingPower,
} from '../../../../../../../types/interfaces';

export enum ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS {
    DELEGATE_ADDRESS = 'delegateAddress',
    ENS_NAME = 'ensName',
    VOTING_POWER = 'votingPower',
    NUMBER_OF_DELEGATORS = 'numberOfDelegators',
    TOTAL_VOTES = 'totalVotes',
    VOTES_1YEAR = 'votes1Year',
    VOTES_3MONTHS = 'votes3Months',
    VOTES_6MONTHS = 'votes6Months',
    GSCORE_RANK = 'gScoreRank',
}

const tableSettingsSevenItems: ICommonTableHeaderSettings = {
    width: {
        desktop: `${95 / 8}%`,
    },
};

const RANK: ICommonTableHeader<ITopDelegatesRank, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: '#',
    rowFieldKey: 'rank',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: {
        width: {
            desktop: `5%`,
        },
    },
};

const GSCORE_RANK: ICommonTableHeader<ITopDelegatesGScore, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'G-score',
    rowFieldKey: 'gScoreRank',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.GSCORE_RANK,
    settings: tableSettingsSevenItems,
};

const ENS_NAME: ICommonTableHeader<ITopDelegatesEnsName, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Delegate',
    rowFieldKey: 'ensName',
    fieldType: COMMON_TABLE_FIELD_TYPES.EXTERNAL_LINK,
    settings: tableSettingsSevenItems,
};

const VOTING_POWER: ICommonTableHeader<ITopDelegatesVotingPower, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Voting Power',
    rowFieldKey: 'votingPower',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTING_POWER,
    settings: tableSettingsSevenItems,
};

const NUMBER_OF_DELEGATORS: ICommonTableHeader<
    ITopDelegatesNumberOfDelegators,
    ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS
> = {
    title: 'Delegators',
    rowFieldKey: 'numberOfDelegators',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.NUMBER_OF_DELEGATORS,
    settings: tableSettingsSevenItems,
};

const TOTAL_VOTES: ICommonTableHeader<ITopDelegatesTotalVotes, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Total votes',
    rowFieldKey: 'totalVotes',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.TOTAL_VOTES,
    settings: tableSettingsSevenItems,
};

const VOTES_1YEAR: ICommonTableHeader<ITopDelegatesVotes1Year, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Votes 1 Year',
    rowFieldKey: 'votes1Year',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTES_1YEAR,
    settings: tableSettingsSevenItems,
};

const VOTES_6MONTHS: ICommonTableHeader<ITopDelegatesVotes6Months, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Votes 6 Months',
    rowFieldKey: 'votes6Months',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTES_6MONTHS,
    settings: tableSettingsSevenItems,
};

const VOTES_3MONTHS: ICommonTableHeader<ITopDelegatesVotes3Months, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS> = {
    title: 'Votes 3 Months',
    rowFieldKey: 'votes3Months',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS.VOTES_3MONTHS,
    settings: tableSettingsSevenItems,
};

const organizationTopDelegatesTableHeaders: ICommonTableHeader<
    IOrganizationTopDelegate,
    ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS
>[] = [
    RANK,
    GSCORE_RANK,
    ENS_NAME,
    VOTING_POWER,
    NUMBER_OF_DELEGATORS,
    TOTAL_VOTES,
    VOTES_3MONTHS,
    VOTES_6MONTHS,
    VOTES_1YEAR,
] as ICommonTableHeader<IOrganizationTopDelegate, ORGANIZATION_TOP_DELEGATES_TABLE_PARAMS>[];

export { organizationTopDelegatesTableHeaders };
