import React, { FC, useEffect, useCallback, useRef, useState } from 'react';
import { useAppSelector } from 'store';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { OrganizationActions } from 'store/actions';
import { OrganizationExecutiveStatsItem } from './components';
import { MetadataTable } from '../OrganizationTreasury/components';
import { daoTreasuryInvestmentsPageIcon, peoplePageIcon } from 'assets';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TooltipWrapper } from 'components/TooltipWrapper';
import { formatNumber } from './helpers';

import styles from './styles.module.scss';

const financeTooltipText = (
    <span>
        <b>Token Price Gain</b>: Measures the gain in token price over the past 3 months (starting from the latest
        update date on DeepDAO) and how it compares to other DAOs in the ecosystem.
        <br />
        <br />
        <b>Treasury Diversity</b>: Looks at the composition of the DAO&apos;s treasury. What percentage of it consists
        of tokens tokens tokens tokens other than the DAO&apos;s voting token (which is typically the main project
        token)? Are other DAOs diversified? (More information about this can be found in the Token tab.)
        <br />
        <br />
        <b>Token Reliance on Treasury</b>: Examines the number of DAO tokens in the treasury compared to the
        token&apos;s circulating supply. This ratio is important for understanding the DAO&apos;s token strategy and
        management approach.
        <br />
        <br />
        <b>Important note</b>: These metrics and data are not financial advice. Please do your own research.
    </span>
);

const governanceTooltipText = (
    <span>
        <b>Active Delegates</b>: How active are the DAO&apos;s delegates? How many of them voted at least once in
        months? This calculating looks at delegates who have more then themselves at a delegator.
        <br />
        <br />
        <b>Dominant Coalition</b>:A coalition is defined as a group of voters and delegates who frequently vote together
        over the past 3 months. How much power do they hold together? Are other DAos in the ecosystem dominated more, or
        less by strong coalitions? See also our Coalitions tab for a detailed breakdown of the DAO&apos;s coalition
        groups. coalition is defined as a group of voters and delegates who frequently vote together over the past 3
        months. How much power do they hold together? Are other DAos in the ecosystem dominated more, or less by strong
        coalitions? See also our Coalitions tab for a detailed breakdown of the DAO&apos;s coalition groups.
        <br />
        <br />
        <b>Loyal Voters</b>: We define a loyal voter as a voter who voted in each of the past 3 years (starting from now
        and going back 12, 24, and 36 months). This metric compares the number of loyal voters over the number of the
        DAO&apos;s governance token holders, and compares this DAO to others in the ecosystem.
        <br />
        <br />
        <b>Missing Voters</b>: We define a missing voter as a voter who voted at least once in the past, but not this
        year (from now and back 12 months). This metric compares the number of missing voters over the number of the
        DAO&apos;s governance token holders, and compares this DAO to others in the ecosystem.
        <br />
        <br />
        <b>Note</b>: a CSV with a full list of the missing, and loyal voters is available for subscribing DAOs.
    </span>
);

export const OrganizationExecutiveSummary: FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const hasFetched = useRef(false);
    const { organizationData } = useAppSelector(({ organization }) => organization);
    const { executiveStats, executiveStatsError } = useAppSelector(
        ({ organization }) => organization,
    );

    const organizationId = organizationData?.id;
    const organizationName = organizationData?.name;

    const fetchExecutiveStats = useCallback(() => {
        if (organizationId && !hasFetched.current) {
            hasFetched.current = true;
            dispatch(OrganizationActions.getExecutiveStats.request({ organizationId }));
        }
    }, [dispatch, organizationId]);

    useEffect(() => {
        fetchExecutiveStats();
    }, [fetchExecutiveStats]);

    const [timeoutExecuted, setTimeoutExecuted] = useState(false);

    useEffect(() => {
        if (!timeoutExecuted) {
            const timeout = setTimeout(() => {
                if (executiveStatsError && Object.keys(executiveStatsError).length > 0) {
                    if (executiveStatsError) {
                        const redirectPath = `/organization/${organizationId}/organization_data/treasury`;
                        history.push(redirectPath);
                    }
                    setTimeoutExecuted(true);
                }
            }, 500);
            return () => clearTimeout(timeout);
        }
    }, [executiveStatsError, timeoutExecuted]);

    if (!executiveStats || Object.keys(executiveStats).length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <>
                <div className={styles.sectionTitle}>
                    <h4>Financial</h4>
                    <TooltipWrapper title={<span>{financeTooltipText}</span>}>
                        <InfoOutlinedIcon className={styles.infoIcon} />
                    </TooltipWrapper>
                </div>
                <div className={styles.gridContainer}>
                    <OrganizationExecutiveStatsItem
                        hasProgressBar={false}
                        hasProgressBarPercentage={true}
                        title="Token Price Gain"
                        icon={daoTreasuryInvestmentsPageIcon}
                        organizationName={organizationName}
                        circularPercentage={executiveStats.tokenPrice.orgRankInPercentage}
                        progressBarTitle={"deepDAO's Latest"}
                        progressBarBottomTitle={`${organizationName}'s token price gained`}
                        progressBarPercentage={executiveStats.tokenPrice.priceGain}
                        progressBarDescription={`over the past 3 months. Last update on DeepDAO at ${new Date(
                            executiveStats.tokenPrice.updatedAt,
                        )
                            .toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                            })
                            .replace(/\//g, '/')}`}
                        description={`${organizationName}'s token price gained more than ${
                            executiveStats.tokenPrice.orgRank - 1
                        } DAOs (${executiveStats.tokenPrice.orgRankInPercentage}%) over the past 3 months.`}
                    />
                    <OrganizationExecutiveStatsItem
                        title="Treasury diversification"
                        icon={daoTreasuryInvestmentsPageIcon}
                        organizationName={organizationName}
                        circularPercentage={executiveStats.treasuryDiversification.orgRankInPercentage}
                        progressBarTitle={'current treasury'}
                        progressBarBottomTitle={`${organizationName} has`}
                        progressBarPercentage={executiveStats.treasuryDiversification.diversificationPercentage}
                        progressBarDescription={'of its treasury in tokens other than its own'}
                        description={`${organizationName} has diversified its treasury more than ${
                            executiveStats.treasuryDiversification.orgRank - 1
                        } DAOs (${executiveStats.treasuryDiversification.orgRankInPercentage}%).`}
                    />
                    <OrganizationExecutiveStatsItem
                        title="Token Reliance on Treasury"
                        icon={daoTreasuryInvestmentsPageIcon}
                        organizationName={organizationName}
                        circularPercentage={executiveStats.tokenReliance.orgRankInPercentage}
                        progressBarTitle={'current reliance'}
                        progressBarBottomTitle={`${organizationName} has`}
                        progressBarPercentage={executiveStats.tokenReliance.tokenReliancePercentage}
                        progressBarDescription={'of its token circulating supply in its treasury'}
                        description={`This ${organizationName} token has a higher percentage in its treasury, vs. its circulating supply, than ${
                            executiveStats.tokenReliance.orgRank - 1
                        } DAOs (${executiveStats.tokenReliance.orgRankInPercentage}%).`}
                    />
                </div>
                <div className={styles.sectionTitle}>
                    <h4>Governance</h4>
                    <TooltipWrapper title={<span>{governanceTooltipText}</span>}>
                        <InfoOutlinedIcon className={styles.infoIcon} />
                    </TooltipWrapper>
                </div>
                <div className={styles.gridContainer}>
                    <OrganizationExecutiveStatsItem
                        hasProgressBar={false}
                        hasProgressBarPercentage={false}
                        title="Active delegates"
                        icon={peoplePageIcon}
                        organizationName={organizationName}
                        circularPercentage={executiveStats.activeDelegatesFor3Month.delegatesRankFor3MonthPercentage}
                        progressBarTitle={'Current delegates'}
                        progressBarBottomTitle={`${organizationName} has`}
                        progressBarPercentage={executiveStats.activeDelegatesFor3Month.delegatesLast3Month || 0}
                        progressBarDescription={`active delegates with minimum 1 vote in the past 3 months`}
                        description={`${organizationName}'s has more active delegates than ${
                            executiveStats.activeDelegatesFor3Month.delegatesRankFor3Month - 1
                        } DAOs (${executiveStats.activeDelegatesFor3Month.delegatesRankFor3MonthPercentage}%) 
                        with Governor delegation.`}
                    />
                    <OrganizationExecutiveStatsItem
                        title="Dominant coalition"
                        icon={peoplePageIcon}
                        organizationName={organizationName}
                        circularPercentage={executiveStats.dominantCoalition.orgRankInPercentage}
                        progressBarTitle={'Current strongest coalition'}
                        progressBarBottomTitle={`${organizationName} has a coalition with`}
                        progressBarPercentage={executiveStats.dominantCoalition.votingPower3MonthWinningPercentage}
                        progressBarDescription={'of the power required for winning a proposal during the past 3 months'}
                        description={`${organizationName}'s strongest coalition is stronger than ${
                            executiveStats.dominantCoalition.orgRank - 1
                        } DAOs (${
                            executiveStats.dominantCoalition.orgRankInPercentage
                        }%) of the DAOs with DeepDAO Pro.`}
                    />
                    <OrganizationExecutiveStatsItem
                        hasProgressBar={false}
                        hasProgressBarPercentage={false}
                        title="Loyal voters"
                        icon={peoplePageIcon}
                        organizationName={organizationName}
                        circularPercentage={executiveStats.loyalVoters.loyalVotersRankPercentage}
                        progressBarTitle={'Current'}
                        progressBarBottomTitle={`${organizationName} has`}
                        progressBarPercentage={executiveStats.loyalVoters.numberOfLoyalVoters}
                        progressBarDescription={`loyal voters, who voted in each of the past 3 years. This is ${formatNumber(
                            executiveStats.loyalVoters.loyalVtsPercentagePerTokenHolders,
                        )} % of the DAO’s token holders.`}
                        description={`${organizationName} has more loyal voters per token holder than ${
                            executiveStats.loyalVoters.loyalVotersRank - 1
                        } DAOs (${formatNumber(executiveStats.loyalVoters.loyalVotersRankPercentage)}%).`}
                    />
                    <OrganizationExecutiveStatsItem
                        hasProgressBar={false}
                        hasProgressBarPercentage={false}
                        title="Missing Voters"
                        icon={peoplePageIcon}
                        organizationName={organizationName}
                        circularPercentage={executiveStats.missingVoters.missingVotersRankPercentage}
                        progressBarTitle={'Current'}
                        progressBarBottomTitle={`${organizationName} has`}
                        progressBarPercentage={executiveStats.missingVoters.numberOfMissingVoters}
                        progressBarDescription={`missing voters, who voted previously in ${organizationName} but not in the past 12 months. This is ${formatNumber(
                            executiveStats.missingVoters.missingVtsPercentagePerTokenHolders,
                        )} % of the DAO’s token holders.`}
                        description={`${organizationName} has more missing voters per token holder than ${
                            executiveStats.missingVoters.missingVotersRank - 1
                        } DAOs (${formatNumber(
                            executiveStats.missingVoters.missingVotersRankPercentage,
                        )}%) of the DAOs.`}
                    />
                </div>
            </>
            <div className={styles.metadata}>
                <div className={styles.data}>
                    <MetadataTable />
                </div>
            </div>
        </div>
    );
};
