import { DEEP_DAO_API } from './apis';
import { DEEP_DAO_MAIL } from './deepDaoLinks';

import { IDetailedPlansPricings, ISelectOptions, IPremiumPlanDetails, ISubscriptionFaq } from 'types/interfaces';

export enum PLANS_PAGES_INDEXES {
    PLANS_INFO = 1,
    ACCOUNT_INFO = 2,
    PAYMENT = 3,
}

export enum PLANS_DURATIONS {
    MONTHLY_PLAN_DURATION = 'MONTHLY',
    QUARTERLY_PLAN_DURATION = 'QUARTERLY',
    // YEARLY_PLAN_DURATION = 'ANNUALLY',
}

export enum BACKEND_PLAN_NAMES {
    STARTER = 'ECOSYSTEM',
    PRO = 'DAO_ANALYTICS',
}

export enum PLAN_NAMES {
    STARTER = 'DeepDAO Pro',
    PRO = 'DAO Analytics API',
    MARKETING = 'MARKETING',
    CUSTOM = 'CUSTOM',
    BASIC = 'BASIC',
}

export enum PLANS_PRICINGS {
    STARTER_MONTHLY = 385,
    STARTER_QUARTERLY = 985,
    // STARTER_YEARLY = 3950,
    PRO_MONTHLY = 450,
    PRO_QUARTERLY = 1195,
    // PRO_YEARLY = 3950,
    CUSTOM = 3000,
}

export enum PLAN_ACTIONS {
    CREATING = 'CREATING',
    UPDATING = 'UPDATING',
}

export enum PAYMENT_PROVIDERS {
    PAYPAL = 'paypal',
}

export enum PAYMENT_STATUSES {
    ACTIVE = 'ACTIVE',
    CANCELED = 'CANCELLED',
}

export const detailedPlansPricings: IDetailedPlansPricings = {
    [PLAN_NAMES.STARTER]: [
        {
            id: 1,
            pricing: PLANS_PRICINGS.STARTER_MONTHLY,
            duration: PLANS_DURATIONS.MONTHLY_PLAN_DURATION,
        },
        {
            id: 2,
            pricing: PLANS_PRICINGS.STARTER_QUARTERLY,
            duration: PLANS_DURATIONS.QUARTERLY_PLAN_DURATION,
        },
    ],
    [PLAN_NAMES.PRO]: [
        {
            id: 1,
            pricing: PLANS_PRICINGS.PRO_MONTHLY,
            duration: PLANS_DURATIONS.MONTHLY_PLAN_DURATION,
        },
        {
            id: 2,
            pricing: PLANS_PRICINGS.PRO_QUARTERLY,
            duration: PLANS_DURATIONS.QUARTERLY_PLAN_DURATION,
        },
    ],
};

export enum PAYMENT_METHODS {
    CREDIT_CARD = 'CREDIT_CARD',
    PAYPAL = 'PAYPAL',
}

export const plansDurations: ISelectOptions[] = [
    {
        id: 1,
        name: 'Monthly',
        value: PLANS_DURATIONS.MONTHLY_PLAN_DURATION,
    },
    {
        id: 2,
        name: '3 Months',
        value: PLANS_DURATIONS.QUARTERLY_PLAN_DURATION,
    },
];

export const paymentMethods: ISelectOptions[] = [
    {
        id: 1,
        name: 'paypal',
        value: PAYMENT_METHODS.PAYPAL,
    },
    {
        id: 2,
        name: 'credit card',
        value: PAYMENT_METHODS.CREDIT_CARD,
    },
];

export const plansDetailsList: IPremiumPlanDetails[] = [
    {
        id: 1,
        title: PLAN_NAMES.STARTER,
        planName: PLAN_NAMES.STARTER,
        monthlyPrice: PLANS_PRICINGS.STARTER_MONTHLY,
        quarterlyPrice: PLANS_PRICINGS.STARTER_QUARTERLY,
        allowButton: true,
        emptyPricing: false,
        documentation: {
            link: DEEP_DAO_API,
            title: 'api.deepdao.io',
        },
        planInfo: [
            {
                id: 1,
                text: 'Pro for top 100 Ethereum and L2 DAOs.',
            },
            {
                id: 2,
                text: 'Executive Summary tab, with key metrics and ecosystem context.',
            },
            {
                id: 3,
                text: 'Expanded treasury data.',
            },
            {
                id: 4,
                text: 'Expanded token data.',
            },
            {
                id: 5,
                text: 'Treasury diversification over time.',
            },
            {
                id: 6,
                text: 'Influentials: Voters, Proposal creators, and multi-sig signers.',
            },
            {
                id: 7,
                text: 'Delegates aggregation: recent, and all time participation.',
            },
            {
                id: 8,
                text: 'Delegate / voter coalitions: analysis of the top power groups in each DAO.',
            },
        ],
    },
    {
        id: 2,
        title: PLAN_NAMES.PRO,
        planName: PLAN_NAMES.PRO,
        allowButton: true,
        disableButton: true,
        emptyPricing: false,
        monthlyPrice: PLANS_PRICINGS.PRO_MONTHLY,
        quarterlyPrice: PLANS_PRICINGS.PRO_QUARTERLY,
        documentation: {
            link: DEEP_DAO_API,
            title: 'api.deepdao.io',
        },
        planInfo: [
            {
                id: 1,
                text: 'People: full DAO Experience profiles, including votes and proposals, rankings, DAO Friends and DAO Antagonists',
            },
            {
                id: 2,
                text: 'Top active participants in each organization based on activity',
            },
            {
                id: 3,
                text: 'Similar organizations (based on mutual voters)',
            },
            {
                id: 5,
                text: 'DAO Participants Boxscore',
            },
            {
                id: 6,
                text: 'Treasury trends (over the past 24h, 7d, 30d, 180d, 1y)',
            },
            {
                id: 7,
                text: 'Governance trends (over the past 24h, 7d, 30d, 180d, 1y)',
            },
            {
                id: 8,
                text: 'API Documentation: ',
                bold: true,
                linkItems: {
                    linkText: 'DeepDAO API',
                    link: 'https://api.deepdao.io',
                },
            },
            {
                id: 10,
                text: 'Call limit: 10,000 monthly',
                bold: true,
            },
        ],
    },
    {
        id: 3,
        title: 'Bulk Data',
        planName: 'Marketing',
        emptyPricing: true,
        showContactLinkInDescription: true,
        planInfo: [
            {
                id: 1,
                text: 'Need more data? Contact us for all your DAO data needs. We welcome academics, researchers, and institutions. ',
            }
        ],
    },
];

export const subscriptionFaqList: ISubscriptionFaq[] = [
    {
        id: 1,
        title: 'The most complete aggregation of DAO data and',
    },
    {
        id: 2,
        title: 'Get a step ahead of your DAO competition',
    },
    {
        id: 3,
        title: 'Trends and insights for navigating the DAO ecosystem.',
    },
    {
        id: 4,
        title: 'For custom data contact us at ',
        linkItems: {
            link: DEEP_DAO_MAIL,
            linkText: 'info@deepdao.io',
        },
    },
];
