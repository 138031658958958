import React, { FC, useEffect, useMemo } from 'react';

import { isEmpty } from 'lodash';

import { LoggedUserCircle } from './components';

import { getAddressFromStorage } from 'web3/storageHelper';
import { useAppSelector, useAppDispatch } from 'store';
import { AuthActions } from 'store/actions';

import { IConnectWalletProps } from 'types/interfaces';

export const ConnectWallet: FC<IConnectWalletProps> = ({ toggleConnection, token, active }: IConnectWalletProps) => {
    const { loggedUserData } = useAppSelector(({ auth }) => auth);
    const dispatch = useAppDispatch();

    const address = getAddressFromStorage();

    useEffect(() => {
        if (active && token && isEmpty(loggedUserData)) {
            dispatch(AuthActions.getLoggedUserData.request({ address }));
        }
    }, [active, token, loggedUserData]);

    const isLoggedUser = useMemo(() => Boolean(token) && !isEmpty(loggedUserData), [token, loggedUserData]);

    return isLoggedUser ? <LoggedUserCircle toggleConnection={toggleConnection} /> : null;
    // <HeaderRectangularButton onClick={toggleConnection} title="Profile" />
};
