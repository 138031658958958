import React, { FC, ReactElement, memo, useMemo } from 'react';

import { Button } from '@material-ui/core';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { HrefLink, TooltipWrapper } from 'components';

import { DEEP_DAO_MAIL } from 'constants/deepDaoLinks';
import { plansDurations, PLANS_PRICINGS, PAYMENT_STATUSES, BACKEND_PLAN_NAMES } from 'constants/plansItems';

import { FormatUtils } from 'utils';

import { IPlanCardProps, IPlanInfo } from 'types/interfaces';
import { planNameFromBackendToFrontend } from 'helpers/planHelpers';
import { useHistoryPush } from 'hooks';

export const PlanCard: FC<IPlanCardProps> = memo(
    ({
        planName,
        planDescription,
        planInfo,
        title,
        monthlyPrice,
        quarterlyPrice,
        emptyPricing,
        allowButton,
        duration,
        onPlanSelect,
        showContactLinkInDescription,
        userSubscriptions,
    }: IPlanCardProps): ReactElement => {

        const { navigateToCancelSubscription } = useHistoryPush();

        const userPlan = useMemo(() => {
            const subscription = userSubscriptions?.find(({ planName: userPlan }) => 
                planName === planNameFromBackendToFrontend(userPlan as BACKEND_PLAN_NAMES)
            );
            return {
                subscription,
                planIsActive: subscription?.keyExpirationDate
                    ? new Date() < new Date(subscription.keyExpirationDate)
                    : false
            };
        }, [userSubscriptions, planName]);

       return (
            <div className={styles.card}>
                <h1 className={styles.planName}>{title}</h1>
                {!emptyPricing && (
                    <div className={styles.pricingInfo}>
                        <>
                            <h2>
                                $
                                {duration.id === plansDurations[0].id
                                    ? FormatUtils.formatNumberShort(monthlyPrice as PLANS_PRICINGS, 3)
                                    : FormatUtils.formatNumberShort(quarterlyPrice as PLANS_PRICINGS, 3)}{' '}
                                USD
                            </h2>
                            <h4>/ For {duration.id === plansDurations[0].id ? 'month' : '3 month'}</h4>
                        </>
                    </div>
                )}

                {planDescription && <h5 className={styles.planDescription}>{planDescription}</h5>}

                {showContactLinkInDescription && (
                    <p className={styles.contactBlock}>
                        Contact us:{' '}
                        <HrefLink href={DEEP_DAO_MAIL} disableTarget className={styles.mail}>
                            <span>info@deepdao.io</span>
                        </HrefLink>
                    </p>
                )}

                <ul className={styles.infoSection}>
                    {planInfo.map(({ id, text, bold, linkItems }: IPlanInfo) => (
                        <li key={id} className={classNames({ [styles.bold]: Boolean(bold) })}>
                            <span>{text}</span>
                            {linkItems && (
                                <HrefLink href={linkItems.link}>
                                    <span>{linkItems.linkText}</span>
                                </HrefLink>
                            )}
                        </li>
                    ))}
                </ul>
                {allowButton && (
                    <Button
                        className={styles.selectPlanButton}
                        disabled = {userPlan.planIsActive}
                        onClick={() => 
                            onPlanSelect(
                                planName,
                                title,
                                (duration.id === plansDurations[0].id ? monthlyPrice : quarterlyPrice) as number,
                            )
                        }
                    >
                       {userPlan.planIsActive ? 'Subscription Active' : 'Select plan'}
                    </Button>
                )}

                {userPlan.planIsActive && (userPlan.subscription?.status !== PAYMENT_STATUSES.CANCELED) && (
                    <Button 
                        className={styles.selectPlanButton}
                        onClick={() => navigateToCancelSubscription(userPlan.subscription?.subscriptionId)}
                    > 
                        Cancel subscription
                    </Button>
                )}
            </div>
        );
    },
);
