import { COMMON_TABLE_FIELD_TYPES, ICommonTableHeader } from '../../../../../../CommonTable/types';

export enum DAO_PROPOSERS_TABLE_PARAMS {
    RANK = 'rank',
    GSCORE_RANKING = 'gScoreRanking',
    PROPOSER = 'proposer',
    TOTAL_PROPOSALS = 'totalProposals',
    WIN_PERCENTAGE = 'winPercentage',
    PROPOSALS_WON_LOST = 'proposalsWonLoss',
}

const RANK: ICommonTableHeader<any, DAO_PROPOSERS_TABLE_PARAMS> = {
    title: '#',
    rowFieldKey: 'rank',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: {
        width: {
            desktop: `8%`,
        },
    },
};

const GSCORE_RANKING: ICommonTableHeader<any, DAO_PROPOSERS_TABLE_PARAMS> = {
    title: 'G-score\nranking',
    rowFieldKey: 'gScoreRanking',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: DAO_PROPOSERS_TABLE_PARAMS.GSCORE_RANKING,
    settings: {
        width: {
            desktop: `19%`,
        },
    },
};

const PROPOSER: ICommonTableHeader<any, DAO_PROPOSERS_TABLE_PARAMS> = {
    title: 'Proposal creator',
    rowFieldKey: 'proposer',
    fieldType: COMMON_TABLE_FIELD_TYPES.EXTERNAL_LINK,
    settings: {
        width: {
            desktop: `23%`,
        },
    },
};

const TOTAL_PROPOSALS: ICommonTableHeader<any, DAO_PROPOSERS_TABLE_PARAMS> = {
    title: 'Proposals',
    rowFieldKey: 'totalProposals',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: DAO_PROPOSERS_TABLE_PARAMS.TOTAL_PROPOSALS,
    settings: {
        width: {
            desktop: `17%`,
        },
    },
};

const WIN_PERCENTAGE: ICommonTableHeader<any, DAO_PROPOSERS_TABLE_PARAMS> = {
    title: 'Win %',
    rowFieldKey: 'winPercentage',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: DAO_PROPOSERS_TABLE_PARAMS.WIN_PERCENTAGE,
    settings: {
        width: {
            desktop: `16%`,
        },
    },
};

const PROPOSALS_WON_LOST: ICommonTableHeader<any, DAO_PROPOSERS_TABLE_PARAMS> = {
    title: 'Won/Loss',
    rowFieldKey: 'proposalsWonLoss',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: {
        width: {
            desktop: `17%`,
        },
    },
};

const daoProposersTableHeaders: ICommonTableHeader<any, DAO_PROPOSERS_TABLE_PARAMS>[] = [
    RANK,
    GSCORE_RANKING,
    PROPOSER,
    TOTAL_PROPOSALS,
    WIN_PERCENTAGE,
    PROPOSALS_WON_LOST,
];

export { daoProposersTableHeaders };
