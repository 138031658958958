import querystring from 'query-string';

import { defaultOrganizationPlaceholderImage } from 'assets';
import { DAO_LOGOS_BUCKET_BASE_URL, IPFS_LOGO_URL, IPFS_MAIN_URL } from 'constants/apis';
import { EXECUTIVE_SUMMARY_URL } from 'constants/organizationTabs';

class LinkUtils {
    static isPathNameEqualsTo = (relatedPaths: string[]): boolean => {
        const pagePathname = window.location.pathname;

        return relatedPaths.some((relatedPath: string) => pagePathname.includes(relatedPath));
    };

    static createDynamicLink = (
        id: number | string,
        page: 'organization' | 'user' | 'feed-proposal' | 'faq' | 'organization-tab',
        tabName?: string,
    ): string => {
        switch (page) {
            case 'organization':
                return `/organization/${id}/organization_data/${EXECUTIVE_SUMMARY_URL}`;

            case 'organization-tab':
                return `/organization/${id}/organization_data/${tabName}`;

            case 'user':
                return `/user/${id}/verified_dao_experience`;

            case 'feed-proposal':
                return `/feed-proposal/${id}`;

            case 'faq':
                return `/faq/${id}`;

            default:
                return '/organizations';
        }
    };

    static getDaoLogoLinkFromBucket = (image: string | null | undefined, defaultImage: string): string =>
        image ? `${DAO_LOGOS_BUCKET_BASE_URL}/${image}` : defaultImage;

    static isValidUrl = (link = ''): boolean => {
        try {
            new URL(link);
            return true;
        } catch (error) {
            return false;
        }
    };

    static isPathNameEndsWith = (pageName: string): boolean => window.location.pathname.endsWith(pageName);

    static getImageLinkFromIPFS = (imageLink: string): string =>
        imageLink.startsWith(IPFS_LOGO_URL) ? imageLink.replace(IPFS_LOGO_URL, IPFS_MAIN_URL) : imageLink;

    static getOrganizationLogo = (image = ''): string =>
        LinkUtils.isValidUrl(image)
            ? image
            : LinkUtils.getDaoLogoLinkFromBucket(image, defaultOrganizationPlaceholderImage);

    static updateLinkWithQueryParams = (pathname: string, query = {}): void => {
        if (!query || Object.keys(query)?.length === 0) {
            return window.history.replaceState(null, '', pathname);
        }

        const searchParams = new URLSearchParams(query);

        window.history.replaceState(null, '', `${pathname}?${searchParams}`);
    };

    static parseQueryParams = (query: string): { [key: string]: string | (string | null)[] | null } =>
        querystring.parse(query, { arrayFormat: 'comma' });
}

export { LinkUtils };
