import React, { FC, ReactElement, useEffect } from 'react';

import styles from './styles.module.scss';

import { TableTabs } from 'components';

import { useAppSelector, useAppDispatch } from 'store';
import { useTabSelector, useOrganizationDaos } from 'hooks';
import { OrganizationActions } from 'store/actions';

import type { ITabItems } from 'types/interfaces';
import { CommonTable } from '../CommonTable';
import { DAO_VOTERS_TABLE_PARAMS, daoVotersTableHeaders } from './constants/dao-voters.constants';
import { LinkUtils } from '../../../../../../utils';
import { DAO_PROPOSERS_TABLE_PARAMS, daoProposersTableHeaders } from './constants/dao-proposers.constants';

export const OrganizationVoters: FC = (): ReactElement => {
    const { organizationDaos, daoVoters, daoProposers } = useAppSelector(({ organization }) => organization);
    const dispatch = useAppDispatch();
    const { tabSelected, handleTabChange } = useTabSelector({} as ITabItems);

    const { organizationHaveDaos, createTabsList, createDaosList, organizationFirstDaoId } = useOrganizationDaos();

    useEffect(() => {
        if (organizationHaveDaos) {
            handleTabChange(createDaosList(0)?.[0] || {});
            dispatch(OrganizationActions.getDaoVoters.request({ daoId: organizationFirstDaoId as string }));
            dispatch(OrganizationActions.getDaoProposers.request({ daoId: organizationFirstDaoId as string }));
        }
    }, [organizationDaos]);

    useEffect(
        () => () => {
            dispatch({ type: OrganizationActions.CLEAR_ORGANIZATION_MEMBERS_DATA });
        },
        [dispatch],
    );

    const onTabChange = (tab: ITabItems) => {
        if (tab.value !== tabSelected.value) {
            handleTabChange(tab);
            dispatch(OrganizationActions.getDaoVoters.request({ daoId: tab.value }));
            dispatch(OrganizationActions.getDaoProposers.request({ daoId: tab.value }));
        }
    };

    const transformedDaoVoters = daoVoters.data.map((v) => ({
        ...v,
        voter: {
            title: v.ensName || v.address,
            link: LinkUtils.createDynamicLink(v.address, 'user'),
        },
        votesWonLoss: `${v.votesWon}/${v.votesLost}`,
    }));

    const transformedDaoProposers = daoProposers.data.map((p) => ({
        ...p,
        proposer: {
            title: p.ensName || p.address,
            link: LinkUtils.createDynamicLink(p.address, 'user'),
        },
        proposalsWonLoss: `${p.proposalsWon}/${p.proposalsLost}`,
    }));

    return (
        <div className={styles.container}>
            <div className={styles.tabsWrapper}>
                <TableTabs
                    tableTabs={createTabsList('Members')}
                    tabSelected={tabSelected}
                    handleTabChange={onTabChange}
                />
            </div>

            <div className={styles.gridContainer}>
                <div className={styles.gridItem}>
                    <CommonTable
                        title="Voters"
                        infoText={``}
                        tableRowsData={transformedDaoVoters as any}
                        loading={daoVoters.loading}
                        tableHeaderData={daoVotersTableHeaders as any}
                        sortedParamName={DAO_VOTERS_TABLE_PARAMS.TOTAL_VOTES}
                        isSmallTable={true}
                    />
                </div>
                <div className={styles.gridItem}>
                    <CommonTable
                        title="Proposal creators"
                        tableRowsData={transformedDaoProposers as any}
                        loading={daoProposers.loading}
                        tableHeaderData={daoProposersTableHeaders as any}
                        sortedParamName={DAO_PROPOSERS_TABLE_PARAMS.TOTAL_PROPOSALS}
                        isSmallTable={true}
                    />
                </div>
            </div>
        </div>
    );
};
