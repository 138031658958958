import React, { FC, ReactElement } from 'react';

import { TableDataNotFound, InfoCircle, Loader } from 'components';
import { IOrganizationHoldingToken } from 'types/interfaces';
import { useAppSelector } from 'store';
import { TOKEN_TAB, TOKEN_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

import { RowItems, TableHeader } from './components';
import styles from './styles.module.scss';

export const OrganizationsHoldingTokenTable: FC = (): ReactElement => {
    const { organizationHoldingToken  } = useAppSelector(({ organizationTabs }) => organizationTabs);

    return (
        <>
            <div className={styles.tableWrapper}>
                <div className={styles.titleWrapper}>
                    <h4 className={styles.tableTitle}>Organizations holding token</h4>
                    <InfoCircle
                        tooltipText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.ORGANIZATIONS_HOLDING_TOKEN]}
                        className={styles.infoIcon}
                    />
                </div>
                {organizationHoldingToken.loading && organizationHoldingToken.data.length === 0 && (
                    <div className={styles.loaderBox}>
                        <Loader size={40} className={styles.similarUsersLoader} />
                    </div>
                )}
                {organizationHoldingToken.data.length > 0 && (
                    <div className={styles.dataContainer}>
                        <div className={styles.tableContainer}>
                            <TableHeader />
                            <div className={styles.rowsWrapper}>
                                <div className={styles.rowsContainer}>
                                    {organizationHoldingToken.data.map((organization: IOrganizationHoldingToken) => (
                                        <RowItems key={organization.organization.id} {...organization} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!organizationHoldingToken.loading && organizationHoldingToken.data.length === 0 && (
                    <TableDataNotFound forTable overflowMarginsSecondVariant />
                )}
            </div>
        </>
    );
};
