import {
    COMMON_TABLE_FIELD_TYPES,
    ICommonTableHeader,
    ICommonTableHeaderSettings,
} from '../../../../../../CommonTable/types';
import {
    IOrganizationTopVoter,
    ITopVotersEnsName,
    ITopVotersGScore,
    ITopVotersRank,
    ITopVotersTotalVotes,
    ITopVotersVotes1Year,
    ITopVotersVotes3Months,
    ITopVotersVotes6Months,
    ITopVotersVotingPower,
} from '../../../../../../../types/interfaces';

export enum ORGANIZATION_TOP_VOTERS_TABLE_PARAMS {
    VOTER_ADDRESS = 'voterAddress',
    ENS_NAME = 'ensName',
    VOTING_POWER = 'votingPower',
    TOTAL_VOTES = 'totalVotes',
    VOTES_1YEAR = 'votes1Year',
    VOTES_3MONTHS = 'votes3Months',
    VOTES_6MONTHS = 'votes6Months',
    GSCORE_RANK = 'gScoreRank',
}

const tableSettingsSevenItems: ICommonTableHeaderSettings = {
    width: {
        desktop: `${95 / 7}%`,
    },
};

const RANK: ICommonTableHeader<ITopVotersRank, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS> = {
    title: '#',
    rowFieldKey: 'rank',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: {
        width: {
            desktop: `5%`,
        },
    },
};

const GSCORE_RANK: ICommonTableHeader<ITopVotersGScore, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS> = {
    title: 'G-score',
    rowFieldKey: 'gScoreRank',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_VOTERS_TABLE_PARAMS.GSCORE_RANK,
    settings: tableSettingsSevenItems,
};

const ENS_NAME: ICommonTableHeader<ITopVotersEnsName, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS> = {
    title: 'Voter',
    rowFieldKey: 'ensName',
    fieldType: COMMON_TABLE_FIELD_TYPES.EXTERNAL_LINK,
    settings: tableSettingsSevenItems,
};

const VOTING_POWER: ICommonTableHeader<ITopVotersVotingPower, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS> = {
    title: 'Voting Power',
    rowFieldKey: 'votingPower',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_VOTERS_TABLE_PARAMS.VOTING_POWER,
    settings: tableSettingsSevenItems,
};

const TOTAL_VOTES: ICommonTableHeader<ITopVotersTotalVotes, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS> = {
    title: 'Total votes',
    rowFieldKey: 'totalVotes',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_VOTERS_TABLE_PARAMS.TOTAL_VOTES,
    settings: tableSettingsSevenItems,
};

const VOTES_1YEAR: ICommonTableHeader<ITopVotersVotes1Year, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS> = {
    title: 'Votes 1 Year',
    rowFieldKey: 'votes1Year',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_VOTERS_TABLE_PARAMS.VOTES_1YEAR,
    settings: tableSettingsSevenItems,
};

const VOTES_6MONTHS: ICommonTableHeader<ITopVotersVotes6Months, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS> = {
    title: 'Votes 6 Months',
    rowFieldKey: 'votes6Months',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_VOTERS_TABLE_PARAMS.VOTES_6MONTHS,
    settings: tableSettingsSevenItems,
};

const VOTES_3MONTHS: ICommonTableHeader<ITopVotersVotes3Months, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS> = {
    title: 'Votes 3 Months',
    rowFieldKey: 'votes3Months',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: ORGANIZATION_TOP_VOTERS_TABLE_PARAMS.VOTES_3MONTHS,
    settings: tableSettingsSevenItems,
};

const organizationTopVotersTableHeaders: ICommonTableHeader<
    IOrganizationTopVoter,
    ORGANIZATION_TOP_VOTERS_TABLE_PARAMS
>[] = [
    RANK,
    GSCORE_RANK,
    ENS_NAME,
    VOTING_POWER,
    TOTAL_VOTES,
    VOTES_3MONTHS,
    VOTES_6MONTHS,
    VOTES_1YEAR,
] as ICommonTableHeader<IOrganizationTopVoter, ORGANIZATION_TOP_VOTERS_TABLE_PARAMS>[];

export { organizationTopVotersTableHeaders };
