import { COMMON_TABLE_FIELD_TYPES, ICommonTableHeader } from '../../../../../../CommonTable/types';

export enum DAO_VOTERS_TABLE_PARAMS {
    RANK = 'rank',
    GSCORE_RANKING = 'gScoreRanking',
    VOTER = 'voter',
    TOTAL_VOTES = 'totalVotes',
    WIN_PERCENTAGE = 'winPercentage',
    VOTES_WON_LOST = 'votesWonLoss',
}

const RANK: ICommonTableHeader<any, DAO_VOTERS_TABLE_PARAMS> = {
    title: '#',
    rowFieldKey: 'rank',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: {
        width: {
            desktop: `8%`,
        },
    },
};

const GSCORE_RANKING: ICommonTableHeader<any, DAO_VOTERS_TABLE_PARAMS> = {
    title: 'G-score\nranking',
    rowFieldKey: 'gScoreRanking',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: DAO_VOTERS_TABLE_PARAMS.GSCORE_RANKING,
    settings: {
        width: {
            desktop: `19%`,
        },
    },
};

const VOTER: ICommonTableHeader<any, DAO_VOTERS_TABLE_PARAMS> = {
    title: 'Voter',
    rowFieldKey: 'voter',
    fieldType: COMMON_TABLE_FIELD_TYPES.EXTERNAL_LINK,
    settings: {
        width: {
            desktop: `23%`,
        },
    },
};

const TOTAL_VOTES: ICommonTableHeader<any, DAO_VOTERS_TABLE_PARAMS> = {
    title: 'Voted',
    rowFieldKey: 'totalVotes',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: DAO_VOTERS_TABLE_PARAMS.TOTAL_VOTES,
    settings: {
        width: {
            desktop: `17%`,
        },
    },
};

const WIN_PERCENTAGE: ICommonTableHeader<any, DAO_VOTERS_TABLE_PARAMS> = {
    title: 'Win %',
    rowFieldKey: 'winPercentage',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    sortedParamName: DAO_VOTERS_TABLE_PARAMS.WIN_PERCENTAGE,
    settings: {
        width: {
            desktop: `16%`,
        },
    },
};

const VOTES_WON_LOST: ICommonTableHeader<any, DAO_VOTERS_TABLE_PARAMS> = {
    title: 'Won/Loss',
    rowFieldKey: 'votesWonLoss',
    fieldType: COMMON_TABLE_FIELD_TYPES.BOLD_TEXT,
    settings: {
        width: {
            desktop: `17%`,
        },
    },
};

const daoVotersTableHeaders: ICommonTableHeader<any, DAO_VOTERS_TABLE_PARAMS>[] = [
    RANK,
    GSCORE_RANKING,
    VOTER,
    TOTAL_VOTES,
    WIN_PERCENTAGE,
    VOTES_WON_LOST,
];

export { daoVotersTableHeaders };
