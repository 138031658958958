import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';

import styles from './styles.module.scss';

import {
    StatsBarGraph,
    StatsCard,
    StatsGraphCard,
    StatsLineGraph,
    OrganizationsTables,
    Loader,
    OrganizationsStatsCard,
    GraphCardHeader,
} from 'components';

import { GRAPH_TYPES } from 'constants/organizationsGraphNames';

import { useStatsDataCreator } from './utils';

import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationsActions, PremiumSubscriptionActions } from 'store/actions';
import { mixpanelService } from 'services';
import { getTitleAndDescriptionMetaTags } from 'helpers';
import { HelmetContext } from 'context/helmetContext';

const organizationTooltipText = (
    <span>
        DAO: An assembly of people making decisions via specific smart governance contracts, typically including DAO
        assets (treasury) management.
        <br />
        <br />
        Organization: The group of all DAOs and sub-DAO governance bodies that are governed in common, via a specific
        governance token(s).
        <br />
        <br />
        Treasury: The total assets that the DAO may use at its own discretion. Excluding DAO-managed but not owned
        assets such as reward fees or staking accounts.
        <br />
        <br />
        Voters and proposal-makers: holders of governance token/s who ever actually voted or proposed in a DAO.
    </span>
);

export const OrganizationsPage: FC = (): ReactElement => {
    const [isBottom, setIsBottom] = useState(false);

    const { loading, paginationLoading, lastUpdate, organizationsCount, enrichedOrganizationsCount } = useAppSelector(
        ({ organizations }) => organizations,
    );
    const dispatch = useAppDispatch();
    const { setMetaTags } = useContext(HelmetContext);

    const {
        potentialVotersData,
        activeVotersProposalsData,
        totalTreasuryData,
        organizationsOverThresholdAumData,
        organizationsOverThresholdActiveMembersData,
    } = useStatsDataCreator();

    const handleUserScroll = () => {
        const heightLimit = 100;

        if (
            heightLimit + window.innerHeight + document.documentElement.scrollTop >
            document.documentElement.scrollHeight
        ) {
            setIsBottom(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleUserScroll);
        return () => window.removeEventListener('scroll', handleUserScroll);
    }, []);

    useEffect(() => {
        if (!loading && !paginationLoading && isBottom) {
            setIsBottom(false);
            dispatch(OrganizationsActions.getMoreOrganizationsData.request());
        }
    }, [paginationLoading, loading, isBottom]);

    useEffect(() => {
        dispatch(OrganizationsActions.getOrganizationsData.request());
        dispatch(PremiumSubscriptionActions.getPremiumUserData.request());
    }, []);

    useEffect(
        () => () => {
            dispatch({ type: OrganizationsActions.CLEAR_ORGANIZATIONS_DATA });
        },
        [dispatch],
    );

    useEffect(() => {
        mixpanelService.track(mixpanelService.eventsGenerator.dashboardEvent());

        setMetaTags(
            getTitleAndDescriptionMetaTags(
                'DeepDAO – Discovery Engine for DAO Ecosystem',
                `DeepDAO offers extensive analytics and information gathering. The main dashboard product lists and ranks blockchain DAOs using key metrics like membership and assets under management.`,
            ),
        );

        return () => {
            setMetaTags(getTitleAndDescriptionMetaTags());
        };
    }, []);

    return (
        <>
            {loading && <Loader />}

            <>
                <StatsCard
                    organizationsCount={organizationsCount}
                    enrichedOrganizationsCount={enrichedOrganizationsCount}
                    lastUpdateDate={lastUpdate}
                    title="Organizations"
                    isDashboardPage
                    externalLink
                    mobileBanner
                >
                    <div className={styles.contentWrapper}>
                        <div className={styles.statsGraphCardsWrapper}>
                            <StatsGraphCard
                                title="Total treasury"
                                titleComplement="USD"
                                graphType={GRAPH_TYPES.LINE_GRAPH}
                                titleFontIncreased
                                infoIconText={organizationTooltipText}
                            >
                                <StatsLineGraph
                                    data={totalTreasuryData.graphData}
                                    headerData={{
                                        ...totalTreasuryData.headerData,
                                        headerValues: totalTreasuryData.headerValues,
                                        organizationsExtendedInfo: true,
                                    }}
                                    graphClass={styles.lineGraph}
                                />
                            </StatsGraphCard>

                            <StatsGraphCard
                                title="Orgs. over threshold AUM"
                                titleComplement="USD"
                                graphType={GRAPH_TYPES.BAR_GRAPH}
                            >
                                <StatsBarGraph
                                    data={organizationsOverThresholdAumData.graphData}
                                    headerText={`Total ${organizationsOverThresholdAumData.totalValue}`}
                                    options={{
                                        yAxisLabel: '# of Organizations',
                                        yLabelPosition: 'center',
                                        customLabel: true,
                                        barSize: 56,
                                        totalValue: organizationsOverThresholdAumData.totalValue,
                                        heigth: 210,
                                        showPercentage: true,
                                    }}
                                />
                            </StatsGraphCard>

                            <div className={styles.organizationsStatsCardContainer}>
                                <GraphCardHeader title="Voters & proposal makers" />
                                <div className={styles.organizationsStatsCardWrapper}>
                                    <OrganizationsStatsCard
                                        totalTitle="Governance token holders"
                                        statsData={potentialVotersData}
                                    />
                                    <hr />
                                    <OrganizationsStatsCard
                                        totalTitle={`Active voters & proposal makers`}
                                        statsData={activeVotersProposalsData}
                                    />
                                </div>
                            </div>

                            <StatsGraphCard
                                title="Orgs. over threshold"
                                titleComplement="Token holders"
                                graphType={GRAPH_TYPES.BAR_GRAPH}
                            >
                                <StatsBarGraph
                                    data={organizationsOverThresholdActiveMembersData.graphData}
                                    headerText="Governance token holders"
                                    options={{
                                        yAxisLabel: '# of Organizations',
                                        yLabelPosition: 'center',
                                        customLabel: true,
                                        totalValue: organizationsOverThresholdActiveMembersData.totalValue,
                                        barSize: 56,
                                        heigth: 210,
                                        showPercentage: true,
                                    }}
                                />
                            </StatsGraphCard>
                        </div>
                    </div>
                </StatsCard>

                <OrganizationsTables />
            </>
        </>
    );
};
