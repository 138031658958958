import { ITabs_Types } from 'types/interfaces';

export const TREASURY = 'treasury';
export const FINANCE = 'finance';
export const TOKEN = 'token';
export const PROPOSALS = 'proposals';
export const MEMBERS = 'members';
export const COALITIONS = 'coalitions';
export const RESEARCH = 'overview';
export const DELEGATES = 'delegates';
export const VOTER_TRENDS = 'voter trends';
export const VOTER_INTERESTS = 'voter interests';
export const VOTER_DECENTRALIZATION = 'voter decentralization';
export const EXECUTIVE_SUMMARY = 'exec. summary';

export const EXECUTIVE_SUMMARY_URL = 'exec.%20summary';

export const BASE_ORGANIZATION_DATA_TABS: ITabs_Types = {
    [TREASURY]: TREASURY,
    [PROPOSALS]: PROPOSALS,
    [COALITIONS]: COALITIONS,
    [RESEARCH]: RESEARCH,
};

export interface IOrganizationTab {
    tabName: string;
    title: string;
    premium: boolean;
    order: number;
}

export const BASE_ORGANIZATION_TABS: IOrganizationTab[] = [
    {
        tabName: TREASURY,
        title: FINANCE,
        premium: false,
        order: 1,
    },
    {
        tabName: PROPOSALS,
        title: PROPOSALS,
        premium: false,
        order: 11,
    },
    {
        tabName: COALITIONS,
        title: COALITIONS,
        premium: false,
        order: 13,
    },
    {
        tabName: RESEARCH,
        title: RESEARCH,
        premium: false,
        order: 14,
    },
];

export const ADDITIONAL_ORGANIZATION_TABS: IOrganizationTab[] = [
    {
        tabName: EXECUTIVE_SUMMARY,
        title: 'Fundamentals',
        premium: true,
        order: 1,
    },
    {
        tabName: TREASURY,
        title: TREASURY,
        premium: true,
        order: 2,
    },
    {
        tabName: TOKEN,
        title: TOKEN,
        premium: true,
        order: 3,
    },
    {
        tabName: VOTER_TRENDS,
        title: VOTER_TRENDS,
        premium: true,
        order: 5,
    },
    {
        tabName: MEMBERS,
        title: MEMBERS,
        premium: false,
        order: 4,
    },
    {
        tabName: DELEGATES,
        title: DELEGATES,
        premium: true,
        order: 6,
    },
    {
        tabName: VOTER_DECENTRALIZATION,
        title: VOTER_DECENTRALIZATION,
        premium: true,
        order: 8,
    },
    {
        tabName: COALITIONS,
        title: COALITIONS,
        premium: true,
        order: 9,
    },
    // {
    //     tabName: VOTER_INTERESTS,
    //     title: 'ecosystem',
    //     premium: true,
    //     order: 10,
    // },
];

export const ORGANIZATION_DATA = 'organization_data';
export const DISCUSSIONS = 'brainstorm';
const ORGANIZATION_DATA_NAME = 'organization data';

export const ORGANIZATION_TABS: ITabs_Types = {
    [ORGANIZATION_DATA]: ORGANIZATION_DATA_NAME,
    [DISCUSSIONS]: DISCUSSIONS,
};

export enum ORGANIZATION_VOTER_INTERESTS_FIELDS {
    YEAR_BY_YEAR = 'oviYearByYear',
    VOTER_RANKS = 'oviVoterRanks',
    VOTER_ECOSYSTEM_PARTICIPATION = 'oviVoterEcosystemParticipation',
    BREAKDOWN_ECOSYSTEM = 'oviBreakdownEcosystem',
    BREAKDOWN_ORG = 'oviBreakdownOrg',
    COMPETITOR_DAOS_FOR_ORG_VOTERS = 'oviCompetitorDaosForOrgVoters',
    COMPETITOR_DAOS_FOR_ORG_TOKEN_HOLDERS = 'oviCompetitorDaosForOrgTokenHolders',
}

export enum ORGANIZATION_VOTER_INTERESTS_TYPES {
    YEAR_BY_YEAR = 'year_by_year',
    VOTER_RANKS = 'voter_ranks',
    VOTER_ECOSYSTEM_PARTICIPATION = 'voter_ecosystem_participation',
    BREAKDOWN_ECOSYSTEM = 'voter_interests_breakdown_ecosystem',
    BREAKDOWN_ORG = 'voter_interests_breakdown_org',
    COMPETITOR_DAOS_FOR_ORG_VOTERS = 'competitor_daos_for_org_voters',
    COMPETITOR_DAOS_FOR_ORG_TOKEN_HOLDERS = 'competitor_daos_for_org_token_holders',
}

export const ORGANIZATION_VOTER_INTERESTS_FIELD_BY_TYPE = {
    [ORGANIZATION_VOTER_INTERESTS_TYPES.YEAR_BY_YEAR]: ORGANIZATION_VOTER_INTERESTS_FIELDS.YEAR_BY_YEAR,
    [ORGANIZATION_VOTER_INTERESTS_TYPES.VOTER_RANKS]: ORGANIZATION_VOTER_INTERESTS_FIELDS.VOTER_RANKS,
    [ORGANIZATION_VOTER_INTERESTS_TYPES.VOTER_ECOSYSTEM_PARTICIPATION]:
        ORGANIZATION_VOTER_INTERESTS_FIELDS.VOTER_ECOSYSTEM_PARTICIPATION,
    [ORGANIZATION_VOTER_INTERESTS_TYPES.BREAKDOWN_ECOSYSTEM]: ORGANIZATION_VOTER_INTERESTS_FIELDS.BREAKDOWN_ECOSYSTEM,
    [ORGANIZATION_VOTER_INTERESTS_TYPES.BREAKDOWN_ORG]: ORGANIZATION_VOTER_INTERESTS_FIELDS.BREAKDOWN_ORG,
    [ORGANIZATION_VOTER_INTERESTS_TYPES.COMPETITOR_DAOS_FOR_ORG_VOTERS]:
        ORGANIZATION_VOTER_INTERESTS_FIELDS.COMPETITOR_DAOS_FOR_ORG_VOTERS,
    [ORGANIZATION_VOTER_INTERESTS_TYPES.COMPETITOR_DAOS_FOR_ORG_TOKEN_HOLDERS]:
        ORGANIZATION_VOTER_INTERESTS_FIELDS.COMPETITOR_DAOS_FOR_ORG_TOKEN_HOLDERS,
};
