import { call, takeLatest, put } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { ALERT_TYPES, ERROR_MESSAGES, SUCCESS_MESSAGES } from 'constants/alert';

import { PremiumSubscription } from 'store/apis';
import { AlertActions, PremiumSubscriptionActions } from 'store/actions';
import {
    setPremiumUserEmailInStorage,
    setPremiumUserRefreshTokenInStorage,
    setPremiumUserTokenInStorage,
} from 'web3/storageHelper';

import {
    IGetSubscriptionAction,
    IPaypalSubscribeAction,
    IPremiumEmailLoginWorker,
    IPremiumOTPLoginWorker,
    IPremiumEmailUpdateWorker,
    IPremiumSubscription,
} from 'types/interfaces';
import { BACKEND_PLAN_NAMES } from 'constants/plansItems';

function* getPaypalSubscriptionWorker({ payload }: IGetSubscriptionAction): SagaIterator {
    try {
        const { data, hasError } = yield call(PremiumSubscription.getPaypalSubscription, payload.id);

        if (hasError) yield put(PremiumSubscriptionActions.getPaypalSubscription.failure());
        else yield put(PremiumSubscriptionActions.getPaypalSubscription.success(data));
    } catch (e) {
        yield put(PremiumSubscriptionActions.getPaypalSubscription.failure(e));
    }
}

function* paypalSubscribeWorker({ payload }: IPaypalSubscribeAction): SagaIterator {
    try {
        const { data: responseData, hasError, error } = yield call(PremiumSubscription.paypalSubscribe, payload.data);
        if (hasError) {
            yield put(
                PremiumSubscriptionActions.paypalSubscribe.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        } else {
            yield put(PremiumSubscriptionActions.paypalSubscribe.success());
            yield call(PremiumSubscription.createApiKey, responseData.subscriptionId);
            payload.redirect();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.paypalSubscribe.failure(e));
    }
}

function* getApiKeyWorker(): SagaIterator {
    try {
        const { data, hasError, error } = yield call(PremiumSubscription.getApiKey);

        if (hasError) {
            yield put(PremiumSubscriptionActions.getApiKey.failure(error.message));
        } else {
            yield put(PremiumSubscriptionActions.getApiKey.success(data));
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.getApiKey.failure(e));
    }
}

function* premiumEmailLoginWorker({ payload }: IPremiumEmailLoginWorker): SagaIterator {
    try {
        const { hasError, error } = yield call(PremiumSubscription.premiumEmailLogin, payload.email);

        if (hasError)
            yield put(
                PremiumSubscriptionActions.premiumEmailLogin.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            setPremiumUserEmailInStorage(payload.email);
            yield put(PremiumSubscriptionActions.premiumEmailLogin.success());
            if (payload.navigate) payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.premiumEmailLogin.failure());
    }
}

function* premiumOTPLoginWorker({ payload }: IPremiumOTPLoginWorker): SagaIterator {
    try {
        const { hasError, error, data } = yield call(
            PremiumSubscription.premiumOTPLogin,
            payload.email,
            payload.otpCode,
        );
        if (hasError)
            yield put(
                PremiumSubscriptionActions.premiumOTPLogin.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            setPremiumUserTokenInStorage(data.jwt);
            setPremiumUserRefreshTokenInStorage(data.refreshToken);

            yield put(
                PremiumSubscriptionActions.premiumOTPLogin.success({
                    premiumAccessToken: data.jwt,
                    premiumRefreshToken: data.refreshToken,
                }),
            );
            const subscriptionData = yield call(PremiumSubscription.getPremiumUserData);
            if (subscriptionData.hasError) {
                yield put({
                    type: AlertActions.SHOW_ALERT,
                    payload: {
                        title: `${ERROR_MESSAGES.SOMETHING_WENT_WRONG}. ${ERROR_MESSAGES.TRY_LATER}, or contact info@deepdao.io`,
                        type: ALERT_TYPES.ERROR,
                    },
                });
            } else {
                payload.navigate(subscriptionData?.data?.planName === 'Ecosystem');
            }
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.premiumOTPLogin.failure());
    }
}

function* premiumEmailUpdateWorker({ payload }: IPremiumEmailUpdateWorker): SagaIterator {
    try {
        const { hasError, error, data } = yield call(PremiumSubscription.premiumEmailUpdate, payload.newEmail);

        if (hasError)
            yield put(
                PremiumSubscriptionActions.premiumEmailUpdate.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            setPremiumUserEmailInStorage(payload.newEmail);
            setPremiumUserTokenInStorage(data.jwt);
            setPremiumUserRefreshTokenInStorage(data.refreshToken);

            yield put({
                type: AlertActions.SHOW_ALERT,
                payload: {
                    title: SUCCESS_MESSAGES.EMAIL_SUCCESSFULLY_UPDATED,
                    type: ALERT_TYPES.SUCCESS,
                },
            });

            yield put(
                PremiumSubscriptionActions.premiumEmailUpdate.success({
                    newEmail: payload.newEmail,
                    premiumAccessToken: data.jwt,
                    premiumRefreshToken: data.refreshToken,
                }),
            );
            payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.premiumEmailUpdate.failure());
    }
}

function* getPremiumUserInvoicesWorker() {
    try {
        const { data, hasError } = yield call(PremiumSubscription.getPremiumUserInvoices);

        if (hasError) yield put(PremiumSubscriptionActions.getPremiumUserInvoices.failure());
        else yield put(PremiumSubscriptionActions.getPremiumUserInvoices.success(data));
    } catch (e) {
        yield put(PremiumSubscriptionActions.getPremiumUserInvoices.failure(e));
    }
}

function* getPremiumUserDataWorker() {
    try {
        const { data, hasError } = yield call(PremiumSubscription.getPremiumUserData);

        if (hasError) yield put(PremiumSubscriptionActions.getPremiumUserData.failure({ premiumUserData: {} }));
        else {
            const subscriptions: IPremiumSubscription[] = data.userSubscriptions;
            const ecosystemSubscription = subscriptions.find(({ planName }) => planName === BACKEND_PLAN_NAMES.STARTER);

            const isValidSubscription = ecosystemSubscription?.keyExpirationDate
                ? new Date(ecosystemSubscription.keyExpirationDate) > new Date()
                : false;
            const isPremiumAllowed = data.role === 'admin' || isValidSubscription;

            yield put(
                PremiumSubscriptionActions.getPremiumUserData.success({
                    premiumUserData: { ...data, isPremiumAllowed },
                }),
            );
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.getPremiumUserData.failure(e));
    }
}

function* cancelPremiumSubscriptionWorker({ payload }: any) {
    try {
        const { hasError, error } = yield call(
            PremiumSubscription.cancelPremiumPaypalSubscription,
            payload.subscriptionId,
        );

        if (hasError)
            yield put(
                PremiumSubscriptionActions.cancelPremiumSubscription.failure(
                    error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            const { data } = yield call(PremiumSubscription.getPremiumUserData);

            yield put({
                type: AlertActions.SHOW_ALERT,
                payload: {
                    title: SUCCESS_MESSAGES.SUBSCRIPTION_CANCELED,
                    type: ALERT_TYPES.SUCCESS,
                },
            });

            yield put(PremiumSubscriptionActions.cancelPremiumSubscription.success({ premiumUserData: data }));
            payload.navigate();
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.cancelPremiumSubscription.failure(e));
    }
}


function* paypalSubscriptionUpdateWorker({ payload }: any) {
    try {
        const { hasError: hasCancelPaypalSubscriptionError, error: cancelPaypalSubscriptionError } = yield call(
            PremiumSubscription.cancelPremiumPaypalSubscription,
            payload.subscriptionId,
        );

        if (hasCancelPaypalSubscriptionError)
            yield put(
                PremiumSubscriptionActions.paypalSubscriptionUpdate.failure(
                    cancelPaypalSubscriptionError?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG,
                ),
            );
        else {
            yield call(paypalSubscribeWorker, {
                type: PremiumSubscriptionActions.PAYPAL_SUBSCRIBE,
                payload,
            });
        }
    } catch (e) {
        yield put(PremiumSubscriptionActions.paypalSubscriptionUpdate.failure(e));
    }
}

export const PremiumSubscriptionsSaga = function* (): SagaIterator {
    yield takeLatest(PremiumSubscriptionActions.PAYPAL_SUBSCRIBE, paypalSubscribeWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_PAYPAL_SUBSCRIPTION, getPaypalSubscriptionWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_API_KEY, getApiKeyWorker);
    yield takeLatest(PremiumSubscriptionActions.PREMIUM_EMAIL_LOGIN, premiumEmailLoginWorker);
    yield takeLatest(PremiumSubscriptionActions.PREMIUM_OTP_LOGIN, premiumOTPLoginWorker);
    yield takeLatest(PremiumSubscriptionActions.PREMIUM_EMAIL_UPDATE, premiumEmailUpdateWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_PREMIUM_USER_INVOICES, getPremiumUserInvoicesWorker);
    yield takeLatest(PremiumSubscriptionActions.GET_PREMIUM_USER_DATA, getPremiumUserDataWorker);
    yield takeLatest(PremiumSubscriptionActions.CANCEL_PREMIUM_SUBSCRIPTION, cancelPremiumSubscriptionWorker);
    yield takeLatest(PremiumSubscriptionActions.PAYPAL_SUBSCRIPTION_UPDATE, paypalSubscriptionUpdateWorker);
};
