import { Reducer } from '@reduxjs/toolkit';

import { PremiumSubscriptionActions } from 'store/actions';
import { setPremiumUserTokenInStorage } from 'web3/storageHelper';

import type {
    IPremiumSubscriptionForm,
    IPremiumSubscriptionActionsType,
    IPremiumSubscriptionState,
    ISubscription,
    IApiKey,
    IActionWithError,
    IPremiumUserInvoiceData,
    IPremiumUserData,
} from 'types/interfaces';

const defaultState: IPremiumSubscriptionState = {
    loading: false,
    form: {
        plan: '',
        startTime: '',
        quantity: 0,
        autoRenewal: false,
        countryCode: 0,
        phoneNumber: 0,
        fullName: '',
        email: '',
        address: '',
        postalCode: 0,
        country: '',
    },
    paypalSubscriptionLoading: false,
    apiKey: null,
    subscriptionLoading: false,
    paypalSubscriptionError: null,
    premiumLoginLoading: false,
    premiumLoginError: null,
    premiumUserInvoices: [],
    premiumUserInvoicesLoading: true,
    premiumAccessToken: null,
    premiumRefreshToken: null,
    premiumUserData: {} as IPremiumUserData,
    premiumUserDataLoading: false,
    paypalSubscription: {} as ISubscription,
    premiumPFToken: null,
    cancelSubscriptionError: null,
};

export const premiumSubscriptionReducer: Reducer<IPremiumSubscriptionState, IPremiumSubscriptionActionsType> = (
    state: IPremiumSubscriptionState = defaultState,
    action: IPremiumSubscriptionActionsType,
): IPremiumSubscriptionState => {
    const { type, payload } = <IPremiumSubscriptionActionsType>action;

    switch (type) {
        case PremiumSubscriptionActions.SAVE_SUBSCRIPTION_FORM: {
            return {
                ...state,
                form: payload as IPremiumSubscriptionForm,
            };
        }

        case PremiumSubscriptionActions.GET_PAYPAL_SUBSCRIPTION: {
            return {
                ...state,
                paypalSubscriptionLoading: true,
            };
        }

        case PremiumSubscriptionActions.GET_PAYPAL_SUBSCRIPTION_SUCCESS: {
            return {
                ...state,
                paypalSubscriptionLoading: false,
                paypalSubscription: payload as ISubscription,
            };
        }

        case PremiumSubscriptionActions.GET_PAYPAL_SUBSCRIPTION_FAILURE: {
            return {
                ...state,
                paypalSubscriptionLoading: false,
            };
        }

        case PremiumSubscriptionActions.GET_API_KEY: {
            return {
                ...state,
                loading: true,
            };
        }

        case PremiumSubscriptionActions.GET_API_KEY_SUCCESS: {
            return {
                ...state,
                loading: false,
                apiKey: (payload as IApiKey).apiKey,
            };
        }

        case PremiumSubscriptionActions.GET_API_KEY_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case PremiumSubscriptionActions.PAYPAL_SUBSCRIBE: {
            return {
                ...state,
                subscriptionLoading: true,
                apiKey: null,
            };
        }

        case PremiumSubscriptionActions.PAYPAL_SUBSCRIBE_SUCCESS: {
            return {
                ...state,
                subscriptionLoading: false,
                paypalSubscriptionError: null,
                premiumUserData: {} as IPremiumUserData,
            };
        }

        case PremiumSubscriptionActions.PAYPAL_SUBSCRIBE_FAILURE: {
            const { error } = action as IActionWithError;

            return {
                ...state,
                subscriptionLoading: false,
                paypalSubscriptionError: error as string,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_EMAIL_LOGIN: {
            return {
                ...state,
                premiumLoginLoading: true,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_EMAIL_LOGIN_SUCCESS: {
            return {
                ...state,
                premiumLoginLoading: false,
                premiumLoginError: null,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_EMAIL_LOGIN_FAILURE: {
            const { error } = action as IActionWithError;

            return {
                ...state,
                premiumLoginLoading: false,
                premiumLoginError: error as string,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_OTP_LOGIN: {
            return {
                ...state,
                premiumLoginLoading: true,
                premiumUserData: {} as IPremiumUserData,
                apiKey: null,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_OTP_LOGIN_SUCCESS: {
            const { premiumAccessToken, premiumRefreshToken } = payload as any;

            return {
                ...state,
                premiumLoginLoading: false,
                premiumLoginError: null,
                premiumAccessToken,
                premiumRefreshToken,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_OTP_LOGIN_FAILURE: {
            const { error } = action as IActionWithError;

            return {
                ...state,
                premiumLoginLoading: false,
                premiumLoginError: error as string,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_EMAIL_UPDATE: {
            return {
                ...state,
                premiumLoginLoading: true,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_EMAIL_UPDATE_SUCCESS: {
            const { newEmail, premiumAccessToken, premiumRefreshToken } = payload as any;

            return {
                ...state,
                premiumLoginLoading: false,
                premiumLoginError: null,
                premiumUserData: { ...state.premiumUserData, email: newEmail },
                premiumAccessToken,
                premiumRefreshToken,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_EMAIL_UPDATE_FAILURE: {
            const { error } = action as any;

            return {
                ...state,
                premiumLoginLoading: false,
                premiumLoginError: error,
            };
        }

        case PremiumSubscriptionActions.CLEAR_PREMIUM_LOGGIN_ERROR: {
            return {
                ...state,
                premiumLoginError: null,
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_USER_INVOICES: {
            return {
                ...state,
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_USER_INVOICES_SUCCESS: {
            return {
                ...state,
                premiumUserInvoicesLoading: false,
                premiumUserInvoices: payload as IPremiumUserInvoiceData[],
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_USER_INVOICES_FAILURE: {
            return {
                ...state,
                premiumUserInvoicesLoading: false,
            };
        }

        case PremiumSubscriptionActions.CLEAR_PREMIUM_INVOICES: {
            return {
                ...state,
                premiumUserInvoices: [],
                premiumUserInvoicesLoading: true,
            };
        }

        case PremiumSubscriptionActions.SET_PREMIUM_ACCESS_TOKEN: {
            const { premiumAccessToken } = payload as any;
            setPremiumUserTokenInStorage(premiumAccessToken);

            return {
                ...state,
                premiumAccessToken: premiumAccessToken,
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_USER_DATA: {
            return {
                ...state,
                premiumUserDataLoading: true,
                apiKey: null,
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_USER_DATA_SUCCESS: {
            const { premiumUserData } = payload as any;

            return {
                ...state,
                premiumUserData: premiumUserData,
                premiumUserDataLoading: false,
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_USER_DATA_FAILURE: {
            return {
                ...state,
                premiumUserDataLoading: false,
            };
        }

        case PremiumSubscriptionActions.CANCEL_PREMIUM_SUBSCRIPTION: {
            return {
                ...state,
            };
        }

        case PremiumSubscriptionActions.CANCEL_PREMIUM_SUBSCRIPTION_SUCCESS: {
            const { premiumUserData } = payload as any;

            return {
                ...state,
                premiumUserData: premiumUserData,
                cancelSubscriptionError: null,
            };
        }

        case PremiumSubscriptionActions.CANCEL_PREMIUM_SUBSCRIPTION_FAILURE: {
            const { error } = action as any;

            return {
                ...state,
                cancelSubscriptionError: error,
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_PF_TOKEN: {
            return {
                ...state,
                premiumPFToken: null,
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_PF_TOKEN_SUCCESS: {
            const { premiumPFToken } = payload as any;

            return {
                ...state,
                premiumPFToken: premiumPFToken,
            };
        }

        case PremiumSubscriptionActions.GET_PREMIUM_PF_TOKEN_FAILURE: {
            return {
                ...state,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_CREDIT_CARD_UPDATE: {
            return {
                ...state,
                subscriptionLoading: true,
            };
        }

        case PremiumSubscriptionActions.PREMIUM_CREDIT_CARD_UPDATE_SUCCESS: {
            const { cardLastFourDigits } = payload as any;

            return {
                ...state,
                subscriptionLoading: false,
                premiumUserData: { ...state.premiumUserData, cardLastFourDigits },
            };
        }

        case PremiumSubscriptionActions.PREMIUM_CREDIT_CARD_UPDATE_FAILURE: {
            const { error } = action as IActionWithError;

            return {
                ...state,
                subscriptionLoading: false,
            };
        }

        case PremiumSubscriptionActions.PAYPAL_SUBSCRIPTION_UPDATE: {
            return {
                ...state,
                subscriptionLoading: true,
                premiumUserData: {} as IPremiumUserData,
                apiKey: null,
            };
        }

        case PremiumSubscriptionActions.PAYPAL_SUBSCRIPTION_UPDATE_SUCCESS: {
            return {
                ...state,
                subscriptionLoading: false,
                paypalSubscriptionError: null,
            };
        }

        case PremiumSubscriptionActions.PAYPAL_SUBSCRIPTION_UPDATE_FAILURE: {
            const { error } = action as IActionWithError;

            return {
                ...state,
                subscriptionLoading: false,
                paypalSubscriptionError: error as string,
            };
        }
        case PremiumSubscriptionActions.CLEAR_PREMIUM_USER_DATA: {
            return defaultState;
        }
        default: {
            return state;
        }
    }
};
