import React, { FC, ReactElement } from 'react';
import styles from './styles.module.scss';

export const TableHeader: FC = (): ReactElement => (
    <div className={styles.wrapper}>
        <div className={styles.tableHeader}>
            <div className={styles.headerItems}>
                <div className={styles.organizationColumn}>
                    <div>
                        <span>Organization</span>
                    </div>
                </div>
                <div className={styles.column}>
                    <div>
                        <span>held tokens</span>
                    </div>
                </div>
                <div className={styles.column}>
                    <div>
                        <span>USD value</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
