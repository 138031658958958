import React, { ReactElement, FC, useContext } from 'react';

import { useMediaQuery } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import styles from './styles.module.scss';

import { MetadataTable, FundsGraph, CurrenciesTable } from './components';

import { RESEARCH } from 'constants/organizationTabs';

import { useAppSelector } from 'store';
import { FormatUtils } from 'utils';
import { OrganizationContext } from 'context/organizationContext';
import { DaoAssetsTable } from './components/DaoAssetsTable';
import { TreasuryCompositionTable } from './components/TreasuryCompositionTable';
import { TreasuryCompositionGraph } from './components/TreasuryCompositionGraph';

const MAX_DESCRIPTION_LENGTH = 150;

export const OrganizationTreasury: FC = (): ReactElement => {
    const isTablet = useMediaQuery('(max-width: 767.98px)');
    const {
        organizationData: { description },
    } = useAppSelector(({ organization }) => organization);
    const { organizationTreasuryComposition, organizationTreasuryCompositionGraph } = useAppSelector(
        ({ organizationTabs }) => organizationTabs,
    );

    const { changeOrganizationDataTab } = useContext(OrganizationContext);
    const shortDescription = FormatUtils.truncateString(description || '', MAX_DESCRIPTION_LENGTH);

    return (
        <div className={styles.container}>
            {description && isTablet && (
                <div className={styles.mobileDescriptionSection}>
                    <div className={styles.textDescription} dangerouslySetInnerHTML={{ __html: shortDescription }} />
                    {description.length > MAX_DESCRIPTION_LENGTH && (
                        <div className={styles.readMore} onClick={() => changeOrganizationDataTab(RESEARCH)}>
                            <span className={styles.text}>Read More</span>
                            <ArrowForwardIcon className={styles.arrowIcon} />
                        </div>
                    )}
                </div>
            )}
            <div className={styles.gridContainer}>
                <div className={styles.gridItem}>
                    <CurrenciesTable />
                </div>

                <div className={styles.gridItem}>
                    <DaoAssetsTable />
                </div>
            </div>
            <div className={styles.treasuryCompositionTable}>
                <TreasuryCompositionGraph
                    organizationTreasuryComposition={organizationTreasuryCompositionGraph.data}
                    loading={organizationTreasuryComposition.loading}
                />
            </div>
            <div className={styles.treasuryOverTime}>
                <div className={styles.data}>
                    <FundsGraph />
                </div>
            </div>
            <div className={styles.treasuryCompositionTable}>
                <TreasuryCompositionTable
                    organizationTreasuryComposition={organizationTreasuryComposition.data}
                    loading={organizationTreasuryComposition.loading}
                />
            </div>
        </div>
    );
};
