import { createApiAction } from 'helpers';

export const SAVE_SUBSCRIPTION_FORM = 'SAVE_SUBSCRIPTION_FORM';

export const PAYPAL_SUBSCRIBE = 'PAYPAL_SUBSCRIBE';
export const PAYPAL_SUBSCRIBE_SUCCESS = 'PAYPAL_SUBSCRIBE_SUCCESS';
export const PAYPAL_SUBSCRIBE_FAILURE = 'PAYPAL_SUBSCRIBE_FAILURE';

export const GET_PAYPAL_SUBSCRIPTION = 'GET_PAYPAL_SUBSCRIPTION';
export const GET_PAYPAL_SUBSCRIPTION_SUCCESS = 'GET_PAYPAL_SUBSCRIPTION_SUCCESS';
export const GET_PAYPAL_SUBSCRIPTION_FAILURE = 'GET_PAYPAL_SUBSCRIPTION_FAILURE';

export const GET_API_KEY = 'GET_API_KEY';
export const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
export const GET_API_KEY_FAILURE = 'GET_API_KEY_FAILURE';

export const PREMIUM_EMAIL_LOGIN = 'PREMIUM_EMAIL_LOGIN';
export const PREMIUM_EMAIL_LOGIN_SUCCESS = 'PREMIUM_EMAIL_LOGIN_SUCCESS';
export const PREMIUM_EMAIL_LOGIN_FAILURE = 'PREMIUM_EMAIL_LOGIN_FAILURE';

export const PREMIUM_OTP_LOGIN = 'PREMIUM_OTP_LOGIN';
export const PREMIUM_OTP_LOGIN_SUCCESS = 'PREMIUM_OTP_LOGIN_SUCCESS';
export const PREMIUM_OTP_LOGIN_FAILURE = 'PREMIUM_OTP_LOGIN_FAILURE';

export const PREMIUM_EMAIL_UPDATE = 'PREMIUM_EMAIL_UPDATE';
export const PREMIUM_EMAIL_UPDATE_SUCCESS = 'PREMIUM_EMAIL_UPDATE_SUCCESS';
export const PREMIUM_EMAIL_UPDATE_FAILURE = 'PREMIUM_EMAIL_UPDATE_FAILURE';

export const GET_PREMIUM_USER_INVOICES = 'GET_PREMIUM_USER_INVOICES';
export const GET_PREMIUM_USER_INVOICES_SUCCESS = 'GET_PREMIUM_USER_INVOICES_SUCCESS';
export const GET_PREMIUM_USER_INVOICES_FAILURE = 'GET_PREMIUM_USER_INVOICES_FAILURE';

export const GET_PREMIUM_USER_DATA = 'GET_PREMIUM_USER_DATA';
export const GET_PREMIUM_USER_DATA_SUCCESS = 'GET_PREMIUM_USER_DATA_SUCCESS';
export const GET_PREMIUM_USER_DATA_FAILURE = 'GET_PREMIUM_USER_DATA_FAILURE';

export const CANCEL_PREMIUM_SUBSCRIPTION = 'CANCEL_PREMIUM_SUBSCRIPTION';
export const CANCEL_PREMIUM_SUBSCRIPTION_SUCCESS = 'CANCEL_PREMIUM_SUBSCRIPTION_SUCCESS';
export const CANCEL_PREMIUM_SUBSCRIPTION_FAILURE = 'CANCEL_PREMIUM_SUBSCRIPTION_FAILURE';

export const GET_PREMIUM_PF_TOKEN = 'GET_PREMIUM_PF_TOKEN';
export const GET_PREMIUM_PF_TOKEN_SUCCESS = 'GET_PREMIUM_PF_TOKEN_SUCCESS';
export const GET_PREMIUM_PF_TOKEN_FAILURE = 'GET_PREMIUM_PF_TOKEN_FAILURE';

export const PREMIUM_CREDIT_CARD_UPDATE = 'PREMIUM_CREDIT_CARD_UPDATE';
export const PREMIUM_CREDIT_CARD_UPDATE_SUCCESS = 'PREMIUM_CREDIT_CARD_UPDATE_SUCCESS';
export const PREMIUM_CREDIT_CARD_UPDATE_FAILURE = 'PREMIUM_CREDIT_CARD_UPDATE_FAILURE';

export const PAYPAL_SUBSCRIPTION_UPDATE = 'PAYPAL_SUBSCRIPTION_UPDATE';
export const PAYPAL_SUBSCRIPTION_UPDATE_SUCCESS = 'PAYPAL_SUBSCRIPTION_UPDATE_SUCCESS';
export const PAYPAL_SUBSCRIPTION_UPDATE_FAILURE = 'PAYPAL_SUBSCRIPTION_UPDATE_FAILURE';

export const CLEAR_PREMIUM_USER_DATA = 'CLEAR_PREMIUM_USER_DATA';

export const CLEAR_PREMIUM_LOGGIN_ERROR = 'CLEAR_PREMIUM_LOGGIN_ERROR';

export const SET_PREMIUM_ACCESS_TOKEN = 'SET_PREMIUM_ACCESS_TOKEN';

export const CLEAR_PREMIUM_INVOICES = 'CLEAR_PREMIUM_INVOICES';

export const paypalSubscribe = createApiAction(PAYPAL_SUBSCRIBE);
export const getPaypalSubscription = createApiAction(GET_PAYPAL_SUBSCRIPTION);
export const getApiKey = createApiAction(GET_API_KEY);
export const premiumEmailLogin = createApiAction(PREMIUM_EMAIL_LOGIN);
export const premiumOTPLogin = createApiAction(PREMIUM_OTP_LOGIN);
export const premiumEmailUpdate = createApiAction(PREMIUM_EMAIL_UPDATE);
export const getPremiumUserInvoices = createApiAction(GET_PREMIUM_USER_INVOICES);
export const getPremiumUserData = createApiAction(GET_PREMIUM_USER_DATA);
export const cancelPremiumSubscription = createApiAction(CANCEL_PREMIUM_SUBSCRIPTION);
export const getPremiumPFToken = createApiAction(GET_PREMIUM_PF_TOKEN);
export const premiumCreditCardUpdate = createApiAction(PREMIUM_CREDIT_CARD_UPDATE);
export const paypalSubscriptionUpdate = createApiAction(PAYPAL_SUBSCRIPTION_UPDATE);
