import React, { ReactElement } from 'react';

import styles from './styles.module.scss';

import { HrefLink, InfoCircle } from 'components';
import { VotingTokenItem } from './components';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { FormatUtils } from 'utils';
import { IVotingToken } from '../../../../../../../../types/interfaces';
import { TOKEN_TAB, TOKEN_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

interface ITrendsTableProps {
    organizationVotingToken: IVotingToken | null;
    lastUpdate: Date | null;
    loading: boolean;
}

export const VotingTokenTable = ({ organizationVotingToken, lastUpdate, loading }: ITrendsTableProps): ReactElement => {
    const tokenValue = FormatUtils.checkIfDataNotExist(organizationVotingToken?.metrics?.current.tokenValue)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(Number(organizationVotingToken?.metrics?.current.tokenValue), 2);
    const totalSupply = FormatUtils.checkIfDataNotExist(organizationVotingToken?.metrics?.current.totalSupply)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(Number(organizationVotingToken?.metrics?.current.totalSupply), 2);
    const circulatingSupply = FormatUtils.checkIfDataNotExist(
        organizationVotingToken?.metrics?.current.circulatingSupply,
    )
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberShort(Number(organizationVotingToken?.metrics?.current.circulatingSupply), 2);
    const circulatingSupplyPercentage = FormatUtils.checkIfDataNotExist(
        organizationVotingToken?.metrics?.current.tokenPercentOfCirculatingSupply,
    )
        ? DATA_NOT_FOUND
        : `${FormatUtils.formatNumberWithCommas(
              Number(organizationVotingToken?.metrics?.current.tokenPercentOfCirculatingSupply),
              2,
          )}`;
    const totalSupplyPercentage =
        organizationVotingToken?.metrics?.current?.totalSupply && organizationVotingToken?.metrics?.current.tokenValue
            ? `${FormatUtils.formatNumberWithCommas(
                  (organizationVotingToken?.metrics?.current.tokenValue /
                      organizationVotingToken?.metrics?.current.totalSupply) *
                      100,
                  2,
              )}`
            : DATA_NOT_FOUND;
    const holders = FormatUtils.checkIfDataNotExist(organizationVotingToken?.metrics?.current.numberOfTokenHolders)
        ? DATA_NOT_FOUND
        : FormatUtils.formatNumberWithCommas(Number(organizationVotingToken?.metrics?.current.numberOfTokenHolders), 0);

    return (
        <div className={styles.content}>
            <div className={styles.headSection}>
                <div className={styles.textSection}>
                    <h4>
                        {`Voting Token: `}
                        <HrefLink href={organizationVotingToken?.token.explorerLink} className={styles.titleLink}>
                            <>
                                <span className={styles.linkText}>
                                    {organizationVotingToken?.token.name}
                                    {organizationVotingToken?.token.name ? ', ' : ''}{' '}
                                </span>
                            </>
                        </HrefLink>
                        <HrefLink href={organizationVotingToken?.token.explorerLink} className={styles.titleLink}>
                            <>
                                <span className={styles.linkText}>{organizationVotingToken?.token.symbol}</span>
                            </>
                        </HrefLink>
                    </h4>
                    <InfoCircle
                        tooltipText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.VOTING_TOKEN]}
                        className={styles.infoIcon}
                    />
                    {lastUpdate && (
                        <h5>Last Update: {FormatUtils.getFormattedDate(lastUpdate as unknown as string)}</h5>
                    )}
                </div>
            </div>
            {!loading && (
                <div className={styles.gridSection}>
                    <VotingTokenItem
                        topTitle={'Tokens in Treasury'}
                        topNumber={tokenValue}
                        bottomTitle={'Token Holders'}
                        bottomNumber={holders}
                        allowCustomDisplay
                    />
                    <VotingTokenItem
                        topTitle={'Total Supply'}
                        topNumber={totalSupply}
                        bottomTitle={'Circulating Supply'}
                        bottomNumber={circulatingSupply}
                        allowCustomDisplay
                    />
                    <VotingTokenItem
                        topTitle={'% total supply in treasury'}
                        topNumber={totalSupplyPercentage}
                        bottomTitle={'% circulating supply in treasury'}
                        bottomNumber={circulatingSupplyPercentage}
                        allowCustomDisplay
                    />
                </div>
            )}
        </div>
    );
};
