import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import classNames from 'classnames';

import copy from 'copy-to-clipboard';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import { AccountSettingBlock, HrefLink, Loader } from 'components';

import { DEEP_DAO_API, DEEP_DAO_API_PRO } from 'constants/apis';
import { DATA_NOT_FOUND } from 'constants/dataNotFound';
import { ALERT_TYPES, SUCCESS_MESSAGES } from 'constants/alert';
import { BACKEND_PLAN_NAMES, PAYMENT_STATUSES, PLAN_NAMES } from 'constants/plansItems';

import { useAppDispatch, useAppSelector } from 'store';
import { AlertActions, PremiumSubscriptionActions } from 'store/actions';
import { FormatUtils } from 'utils';
import { useHistoryPush } from 'hooks';
import { getApiKeyExpirationInfo } from './utils';
import { planNameFromBackendToFrontend } from 'helpers/planHelpers';

export const AccountSettingsPage: FC = (): ReactElement => {
    const dispatch = useAppDispatch();

    const { premiumUserData, premiumUserDataLoading, apiKey } = useAppSelector(
        ({ premiumSubscription }) => premiumSubscription,
    );  
  
    const deepDAOProSubscription = useMemo(
        () =>
          premiumUserData?.userSubscriptions?.find(
            ({ planName }) => planName === BACKEND_PLAN_NAMES.STARTER,
          ),
        [premiumUserData?.userSubscriptions],
      );
    
    const analyticsApiSubscription = useMemo(
    () =>
        premiumUserData?.userSubscriptions?.find(
        ({ planName }) => planName === BACKEND_PLAN_NAMES.PRO,
        ),
    [premiumUserData?.userSubscriptions],
    );
    
    const { navigateToProducts } = useHistoryPush();

    useEffect(() => {
        if (isEmpty(premiumUserData)) dispatch(PremiumSubscriptionActions.getPremiumUserData.request());
    }, []);

    useEffect(() => {
        if (analyticsApiSubscription && !apiKey) {
            dispatch(
                PremiumSubscriptionActions.getApiKey.request(),
            );
        }
    }, [analyticsApiSubscription, apiKey]);

    const isExperitedKey = (keyExpirationDate: string | undefined) => {
        if (keyExpirationDate)  {
            return (new Date() < new Date(keyExpirationDate));
        }
        return false;
    }

    const onCopyApiKeyClick = useCallback(() => {
        if (apiKey) {
            copy(apiKey);
            dispatch({
                type: AlertActions.SHOW_ALERT,
                payload: {
                    title: SUCCESS_MESSAGES.SUCCESFULLY_COPIED,
                    type: ALERT_TYPES.SUCCESS,
                },
            });
        }
    }, [apiKey]);

    const apiKeyExpirationInfo = getApiKeyExpirationInfo(analyticsApiSubscription?.keyExpirationDate);

    const optionsList: any = useMemo(
        () => [
            {
                id: 1,
                sectionTitle: <p className={styles.sectionTitle}>API Key</p>,
                description: (
                    <>
                        <h6 className={styles.descriptionText}>{apiKey || DATA_NOT_FOUND}</h6>
                        {analyticsApiSubscription?.keyExpirationDate && (
                            <h6
                                className={classNames(styles.descriptionText, styles.descriptionTextMarginTop, {
                                    [styles.boldText]: analyticsApiSubscription?.cancelledPaymentDate,
                                })}
                            >
                                Your key is valid until {apiKeyExpirationInfo?.keyExpirationDateFormatted}
                            </h6>
                        )}
                    </>
                ),
                className: styles.alignPositionCenter,
                dependingFields: [
                    analyticsApiSubscription?.status === PAYMENT_STATUSES.ACTIVE || apiKeyExpirationInfo?.isKeyValid,
                ],
                option: (
                    <Button
                        className={styles.buttonGreen}
                        disableRipple
                        disabled={!Boolean(apiKey)}
                        onClick={onCopyApiKeyClick}
                    >
                        copy
                    </Button>
                ),
            },
            {
                id: 2,
                sectionTitle: (
                    <p className={styles.sectionTitle}>
                        {deepDAOProSubscription?.status && isExperitedKey(deepDAOProSubscription?.keyExpirationDate) ? (
                            <>
                                <span className={styles.planName}>{planNameFromBackendToFrontend(deepDAOProSubscription?.planName as BACKEND_PLAN_NAMES)} </span>
                            </>
                        ) : (
                            <span>{`You do not have a DeepDAO PRO subscription`}</span>
                        )}
                    </p>
                ),
                description: (
                    <>
                        {deepDAOProSubscription?.status && isExperitedKey(deepDAOProSubscription?.keyExpirationDate) && (
                            <>
                                <h6 className={styles.descriptionText}>
                                    {deepDAOProSubscription?.cancelledPaymentDate
                                        ? `Subscribed from ${FormatUtils.getFormattedShortDate(
                                            deepDAOProSubscription?.paymentDate,
                                          )} - ${FormatUtils.getFormattedShortDate(deepDAOProSubscription?.keyExpirationDate)}`
                                        : `Subscribed from ${FormatUtils.getFormattedShortDate(
                                            deepDAOProSubscription?.paymentDate,
                                          )}. Renews
                      automatically on ${FormatUtils.getFormattedShortDate(deepDAOProSubscription?.nextBillingTime)}`}
                                </h6>

                                <h6
                                    className={classNames(styles.descriptionText, styles.descriptionTextMarginTop, {
                                        [styles.boldText]: deepDAOProSubscription?.cancelledPaymentDate,
                                    })}
                                >
                                    {deepDAOProSubscription?.cancelledPaymentDate
                                        ? `Canceled on ${FormatUtils.getFormattedShortDate(
                                            deepDAOProSubscription?.cancelledPaymentDate,
                                          )}`
                                        : `Billing: $${FormatUtils.formatNumberShort(deepDAOProSubscription?.value, 2)} ${
                                            (deepDAOProSubscription?.planPeriod === 'quarterly') ?  'every 3 month' : deepDAOProSubscription?.planPeriod
                                          }`}
                                </h6>
                            </>
                        )}
                    </>
                ),
                option: deepDAOProSubscription?.status === PAYMENT_STATUSES.CANCELED && (
                    <Button className={styles.buttonGreen} disableRipple onClick={navigateToProducts}>
                        see plans
                    </Button>
                ),
            },
            {
                id: 3,
                sectionTitle: (
                    <p className={styles.sectionTitle}>
                        {analyticsApiSubscription?.status && isExperitedKey(analyticsApiSubscription.keyExpirationDate) ? (
                            <>
                                <span className={styles.planName}>{planNameFromBackendToFrontend(analyticsApiSubscription?.planName as BACKEND_PLAN_NAMES)}</span>
                                {analyticsApiSubscription?.status  === PAYMENT_STATUSES.CANCELED ? (
                                    ''
                                ) : (
                                    <HrefLink href={DEEP_DAO_API} className={styles.titleLink}>
                                        <>
                                            <span className={styles.linkText}>: api.deepdao.io</span>
                                        </>
                                    </HrefLink>
                                )}
                            </>
                        ) : (
                            <span>{`You do not have a DAO Analytics API subscription`}</span>
                        )}
                    </p>
                ),
                description: (
                    <>
                        {analyticsApiSubscription?.status && isExperitedKey(analyticsApiSubscription.keyExpirationDate) && (
                            <>
                                <h6 className={styles.descriptionText}>
                                    {analyticsApiSubscription?.cancelledPaymentDate
                                        ? `Subscribed from ${FormatUtils.getFormattedShortDate(
                                            analyticsApiSubscription?.paymentDate,
                                          )} - ${FormatUtils.getFormattedShortDate(analyticsApiSubscription?.keyExpirationDate)}`
                                        : `Subscribed from ${FormatUtils.getFormattedShortDate(
                                            analyticsApiSubscription?.paymentDate,
                                          )}. Renews
                      automatically on ${FormatUtils.getFormattedShortDate(analyticsApiSubscription?.nextBillingTime)}`}
                                </h6>
                                

                                <h6
                                    className={classNames(styles.descriptionText, styles.descriptionTextMarginTop, {
                                        [styles.boldText]: analyticsApiSubscription?.cancelledPaymentDate,
                                    })}
                                >
                                    {analyticsApiSubscription?.cancelledPaymentDate
                                        ? `Canceled on ${FormatUtils.getFormattedShortDate(
                                            analyticsApiSubscription?.cancelledPaymentDate,
                                          )}`
                                        : `Billing: $${FormatUtils.formatNumberShort(analyticsApiSubscription?.value, 2)} ${
                                            (analyticsApiSubscription?.planPeriod === 'quarterly') ?  'every 3 month' : analyticsApiSubscription?.planPeriod
                                          }`}
                                </h6>
                            </>
                        )}
                    </>
                ),
                option: analyticsApiSubscription?.status === PAYMENT_STATUSES.CANCELED && (
                    <Button className={styles.buttonGreen} disableRipple onClick={navigateToProducts}>
                        see plans
                    </Button>
                ),
            },
            {
                id: 4,
                sectionTitle: <p className={styles.sectionTitle}>Documentation</p>,
                description: (
                    <div className={styles.apiLinksWrapper}>
                        <HrefLink href={DEEP_DAO_API} className={styles.textLink}>
                            <span className={styles.text}>api.deepdao.io</span>
                        </HrefLink>
                    </div>
                ),
            },
            {
                id: 5,
                sectionTitle: <p className={styles.sectionTitle}>Account email</p>,
                description: <h6 className={styles.descriptionText}>{premiumUserData.email}</h6>,
                option: (
                    <Link to="/premium_login_update" className={styles.link}>
                        <span className={styles.linkTitle}>Update</span> <ArrowBackIos className={styles.arrowIcon} />
                    </Link>
                ),
            },
            // {
            //     id: 7,
            //     sectionTitle: <p className={styles.sectionTitle}>Invoices</p>,
            //     className: styles.alignPositionCenter,
            //     option: (
            //         <Link to="/premium_account_invoices" className={styles.link}>
            //             <span className={styles.linkTitle}>View</span> <ArrowBackIos className={styles.arrowIcon} />
            //         </Link>
            //     ),
            // },
        ],
        [apiKey, onCopyApiKeyClick, premiumUserData, apiKeyExpirationInfo],
    );

    return (
        <div className={styles.wrapper}>
            {premiumUserDataLoading ? (
                <Loader />
            ) : (
                <div className={styles.container}>
                    <h1 className={styles.title}>Settings</h1>
                    <div className={styles.optionsContainer}>
                        {optionsList
                            .filter((items: any) =>
                                items.dependingFields ? items.dependingFields.every((items: any) => items) : items,
                            )
                            .map((items: any) => (
                                <AccountSettingBlock key={items.id} {...items} />
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};
