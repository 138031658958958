import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { Loader, OrganizationHeader, OrganizationPageBody } from 'components';

import { ORGANIZATION_TABS, ORGANIZATION_DATA, DISCUSSIONS, EXECUTIVE_SUMMARY_URL } from 'constants/organizationTabs';
import { getDeepDAOTokenFromStorage } from 'web3/storageHelper';

import { useAppDispatch, useAppSelector } from 'store';
import { OrganizationActions, OrganizationTabsActions, PremiumSubscriptionActions } from 'store/actions';
import { mixpanelService } from 'services';
import { OrganizationContext } from 'context/organizationContext';
import { FormatUtils, getOrganizationMeta } from 'utils';
import { HelmetContext } from 'context/helmetContext';

export const OrganizationPage: FC = (): ReactElement => {
    const {
        loading,
        organizationData: { name, id, description },
    } = useAppSelector(({ organization }) => organization);
    const { token, refreshToken } = useAppSelector(({ auth }) => auth);
    const dispatch = useAppDispatch();
    const { setMetaTags } = useContext(HelmetContext);

    const { organizationId, tab, organization_data_tab } =
        useParams<{ organizationId: string; tab: string; organization_data_tab: string }>();

    const [mainTabSelected, setTabSelected] = useState(ORGANIZATION_TABS[tab] ? tab : DISCUSSIONS);
    const [organizationDataTabSelected, setOrganizationDataTabSelected] = useState(organization_data_tab);

    const history = useHistory();

    useEffect(() => {
        name &&
            mixpanelService.track(
                mixpanelService.eventsGenerator.organizationSelectedEvent(name, organizationDataTabSelected),
            );
        if (mainTabSelected === ORGANIZATION_DATA) window.history.replaceState(null, '', organizationDataTabSelected);
    }, [organizationDataTabSelected]);

    useEffect(() => {
        const organizationMeta =
            name && getOrganizationMeta(`${name} - ${FormatUtils.truncateString(description ?? '', 160)}`, name);
        setMetaTags(organizationMeta);

        return () => {
            const organizationMeta = getOrganizationMeta();
            setMetaTags(organizationMeta);
        };
    }, [name, getOrganizationMeta]);

    useEffect(() => {
        mixpanelService.track(mixpanelService.eventsGenerator.organizationEvent());
        dispatch(PremiumSubscriptionActions.getPremiumUserData.request());
    }, []);

    const changeMainTab = (key: string) => {
        if (key === ORGANIZATION_DATA && mainTabSelected !== ORGANIZATION_DATA) {
            history.push(`/organization/${organizationId}/organization_data/${EXECUTIVE_SUMMARY_URL}`);
        } else if (key === DISCUSSIONS && mainTabSelected !== DISCUSSIONS) {
            history.push(`/organization/${organizationId}/brainstorm`);
        }
        setTabSelected(key);
    };

    const changeOrganizationDataTab = (key: string) => setOrganizationDataTabSelected(key);

    useEffect(() => {
        if (id !== organizationId) {
            dispatch(OrganizationActions.getOrganizationData.request({ organizationId }));
            dispatch(OrganizationActions.getOrganizationAumGraphData.request({ organizationId }));
            dispatch(OrganizationActions.getOrganizationCurrenciesData.request({ organizationId }));
            dispatch(OrganizationActions.getOrganizationToolsData.request());
            dispatch(OrganizationActions.getOrganizationProjectTokenData.request({ organizationId }));
            dispatch(OrganizationActions.getOrganizationDaosData.request({ organizationId }));
            dispatch(OrganizationActions.getOrganizationSubstormsData.request({ organizationId }));
            dispatch(OrganizationTabsActions.getOrganizationTabs.request({ organizationId }));
            dispatch(OrganizationActions.getOrganizationDaoAssetsTable.request({ organizationId }));
            dispatch(OrganizationTabsActions.getOrganizationTreasuryTrends.request({ organizationId }));
            dispatch(OrganizationTabsActions.getOrganizationTreasuryComposition.request({ organizationId }));
            dispatch(OrganizationTabsActions.getOrganizationTreasuryCompositionGraph.request({ organizationId }));
        }
    }, [dispatch]);

    useEffect(() => {
        const isLogged = Boolean(token);

        dispatch(OrganizationActions.getUserOrganizationRole.request({ organizationId, isLogged }));
        dispatch(OrganizationActions.getOrganizationDaoAssets.request({ organizationId }));
        dispatch(OrganizationActions.getOrganizationIsFollowingData.request({ organizationId, isLogged }));
        if (mainTabSelected === DISCUSSIONS)
            dispatch(
                OrganizationActions.getOrganizationBalance.request({
                    organizationId,
                    isLogged: Boolean(getDeepDAOTokenFromStorage()),
                }),
            );
    }, [refreshToken]);

    return (
        <OrganizationContext.Provider
            value={{
                mainTabSelected,
                changeMainTab,
                organizationDataTabSelected,
                changeOrganizationDataTab,
            }}
        >
            {loading ? (
                <Loader />
            ) : (
                <>
                    <OrganizationHeader />
                    <OrganizationPageBody />
                </>
            )}
        </OrganizationContext.Provider>
    );
};
