import React, { FC, ReactElement } from 'react';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { OrganizationStatistics } from './components';

import { DATA_NOT_FOUND } from 'constants/dataNotFound';

import { useAppSelector } from 'store';
import { FormatUtils } from 'utils';
enum AUM_KEY {
    LIQUID = 'liquid',
    VESTING = 'vesting',
}
export const OrganizationDetailsCard: FC = (): ReactElement => {
    const {
        organizationData: { rankings, aum, membersCount, lifetimeParticipants, proposalsCount, aumData },
    } = useAppSelector(({ organization }) => organization);

    const findValueInAum = (name: AUM_KEY) => {
        const aumItemValue = aumData?.find((item) => item.name === name)?.value;
        return FormatUtils.checkIfDataNotExist(aumItemValue)
            ? DATA_NOT_FOUND
            : FormatUtils.formatNumberShort(aumItemValue as number, 1);
    };

    const liquidValue = findValueInAum(AUM_KEY.LIQUID);
    const vestingValue = findValueInAum(AUM_KEY.VESTING);

    return (
        <div className={styles.card}>
            <div className={styles.treasuryBlock}>
                <div className={styles.treasuryInfo}>
                    <span className={styles.title}>treasury</span>
                    <h3 className={styles.treasuryValue}>
                        {FormatUtils.checkIfDataNotExist(aum)
                            ? DATA_NOT_FOUND
                            : '$' + FormatUtils.formatNumberShort(aum as number, 1)}
                    </h3>
                    <div className={styles.liquidVestingValues}>
                        <div>
                            <span>liquid</span>
                            <h6>{liquidValue}</h6>
                        </div>
                        <div>
                            <span>vesting</span>
                            <h6>{vestingValue}</h6>
                        </div>
                    </div>
                </div>
                {/* extract to separate component */}
                <div className={styles.rankingsContainer}>
                    <div className={styles.rankInfo}>
                        <span>
                            {FormatUtils.checkIfDataNotExist(rankings?.treasuryRankingPosition)
                                ? DATA_NOT_FOUND
                                : '#' + FormatUtils.formatNumberShort(rankings?.treasuryRankingPosition as number, 1)}
                        </span>
                        <span>RANK</span>
                    </div>
                    <div className={styles.ranksChangesInfo}>
                        <div className={styles.rankChangesDefault}>
                            {!FormatUtils.checkIfDataNotExist(rankings?.treasuryRankingPositionShift) ? (
                                <>
                                    <ArrowUpwardIcon
                                        className={classNames(styles.arrowIcon, {
                                            [styles.arrowIconPositive]:
                                                Number(rankings?.treasuryRankingPositionShift) > 0,
                                            [styles.arrowIconNegative]:
                                                Number(rankings?.treasuryRankingPositionShift) < 0,
                                        })}
                                    />
                                    <span className={styles.rankShift}>{rankings?.treasuryRankingPositionShift}</span>
                                </>
                            ) : (
                                <span className={styles.notFoundText}>{DATA_NOT_FOUND}</span>
                            )}
                        </div>
                        <span className={styles.period}>1 month</span>
                    </div>
                </div>
            </div>
            <div className={styles.statistics}>
                <OrganizationStatistics
                    title="token holders"
                    amount={membersCount}
                    rank={rankings?.membersRankingPosition}
                    rankChanges={rankings?.membersRankingPositionShift}
                />

                <OrganizationStatistics
                    title="lifetime participants"
                    amount={lifetimeParticipants}
                    rank={rankings?.lifetimeParticipantsRankingPosition}
                    rankChanges={rankings?.lifetimeParticipantsRankingPositionShift}
                />

                <OrganizationStatistics
                    title="proposals"
                    amount={proposalsCount}
                    rank={rankings?.proposalsRankingPosition}
                    rankChanges={rankings?.proposalsRankingPositionShift}
                />

                {/*<div className={styles.participationBlock}>*/}
                {/*    <div className={styles.participationInfo}>*/}
                {/*        <p className={styles.title}>lifetime active users</p>*/}
                {/*        <span className={styles.participationPercentage}>*/}
                {/*            {FormatUtils.checkIfDataNotExist(voterParticipation)*/}
                {/*                ? DATA_NOT_FOUND*/}
                {/*                : voterParticipation?.toFixed(1) + '%'}*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    {!FormatUtils.checkIfDataNotExist(voterParticipation) ? (*/}
                {/*        <div className={styles.progressBar}>*/}
                {/*            <div*/}
                {/*                className={styles.participationStatus}*/}
                {/*                style={{*/}
                {/*                    width: `${((voterParticipation as number) <= MAX_PARTICIPATION_VALUE*/}
                {/*                        ? (voterParticipation as number)*/}
                {/*                        : MAX_PARTICIPATION_VALUE*/}
                {/*                    ).toFixed(1)}%`,*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    ) : null}*/}
                {/*</div>*/}
            </div>
        </div>
    );
};
