import React, { FC, ReactElement } from 'react';
import { TableHeader, RowItems } from './components';
import { TableDataNotFound, InfoCircle, Loader } from 'components';
import { TOKEN_TAB, TOKEN_TAB_DESCRIPTIONS } from '../../../../../../../../constants';

import { useAppSelector } from 'store';
import { tableDataSort } from 'helpers';

import { ISortedParam } from 'types/interfaces';

import styles from './styles.module.scss';

export const TopTokenHoldersTable: FC = (): ReactElement => {
    const { organizationTopShareholdersByHoldings, topShareholdersByHoldingsLoading } = useAppSelector(
        ({ organization }) => organization,
    );

    const [sortedParam, setSortedParam] = React.useState<ISortedParam>({
        paramName: 'tokenHolding',
        descending: true,
    });

    const sortByParam = (param: string) => {
        setSortedParam({
            paramName: param,
            descending: sortedParam.paramName === param ? !sortedParam.descending : true,
        });
    };

    const tableData = tableDataSort([...organizationTopShareholdersByHoldings], sortedParam);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tableWrapper}>
                <div className={styles.titleWrapper}>
                    <h4 className={styles.tableTitle}>Top Token Holders</h4>
                    <InfoCircle
                        tooltipText={TOKEN_TAB_DESCRIPTIONS[TOKEN_TAB.TOP_TOKEN_HOLDERS]}
                        className={styles.infoIcon}
                    />
                </div>
                {topShareholdersByHoldingsLoading && organizationTopShareholdersByHoldings.length === 0 && (
                    <div className={styles.loaderBox}>
                        <Loader size={40} className={styles.similarUsersLoader} />
                    </div>
                )}
                {organizationTopShareholdersByHoldings.length > 0 && (
                    <div className={styles.dataContainer}>
                        <div className={styles.tableContainer}>
                            <TableHeader sortByParam={sortByParam} sortedParamName={sortedParam.paramName} />

                            <div className={styles.rowsWrapper}>
                                <div className={styles.rowsContainer}>
                                    {tableData.map((currencyItems) => (
                                        <RowItems key={currencyItems.daoId} {...currencyItems} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!topShareholdersByHoldingsLoading && organizationTopShareholdersByHoldings.length === 0 && (
                    <TableDataNotFound forTable overflowMarginsSecondVariant />
                )}
            </div>
        </div>
    );
};
