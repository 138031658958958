import { ITableTabs } from 'types/interfaces';

const tabItems: ITableTabs = {
    title: 'Delegate Coalitions',
    tabsList: [
        {
            text: 'duos',
            value: 'duos',
            id: 2,
        },
        {
            text: 'trios',
            value: 'trios',
            id: 3,
        },
        {
            text: 'fours',
            value: 'fours',
            id: 4,
        },
        {
            text: 'fives',
            value: 'fives',
            id: 5,
        },
        // {
        //     text: 'sixes',
        //     value: 'sixes',
        //     id: 6,
        // },
    ],
};

enum COALITIONS_PARAMS {
    COALITIONS_PARAM = 'names',
    VOTED_TOGETHER_PARAM = 'timesVotedTogether',
    DELEGATORS_NUMBER = 'delegatorsNumber',
    VOTING_POWER = 'votingPower',
    VOTING_POWER_PERCENTAGE = 'votingPowerPercentage',
    CIRCULATING_SUPPLY_PERCENTAGE = 'votingPowerCirculatingSupplyPercentage',
    NEEDED_FOR_WINNING_PERCENTAGE = 'votingPowerNeededForWinningPercentage',
    SIX_M_WINNING_PERCENTAGE = 'votingPower6MonthWinningPercentage',
}

export { tabItems, COALITIONS_PARAMS };
