import { call, takeLatest, put } from 'redux-saga/effects';

import { SagaIterator } from '@redux-saga/core';

import { ERROR_MESSAGES } from 'constants/alert';

import { PremiumClients } from 'store/apis';
import { ClientsActions } from 'store/actions';
import {
    setPremiumUserRefreshTokenInStorage,
    setPremiumUserTokenInStorage,
} from 'web3/storageHelper';

import { IPremiumClient, IPremiumClientsActionsType } from 'types/interfaces';

function* saveClientWorker({ payload }: IPremiumClientsActionsType): SagaIterator {
    try {
        const { hasError, error, headers } = yield call(PremiumClients.saveClient, payload as IPremiumClient);
        if (hasError) {
            yield put(ClientsActions.saveClient.failure(error?.message || ERROR_MESSAGES.SOMETHING_WENT_WRONG));
        } else {
            setPremiumUserTokenInStorage(headers['access-token']);
            setPremiumUserRefreshTokenInStorage(headers['refresh-token']);
            yield put(ClientsActions.saveClient.success(payload));
            payload?.navigate && payload.navigate();
        }
    } catch (e) {
        yield put(ClientsActions.saveClient.failure(e));
    }
}

export const PremiumClientsSaga = function* (): SagaIterator {
    yield takeLatest(ClientsActions.SAVE_CLIENT, saveClientWorker);
};
