import { PRO_QUARTERLY_PLAN,  PRO_MONTHLY_PLAN, STARTER_MONTHLY_PLAN, STARTER_QUARTERLY_PLAN} from 'constants/apis';
import { PLANS_DURATIONS, PLAN_NAMES } from 'constants/plansItems';

export const getCorrectPaypalId = (
    planName: PLAN_NAMES, 
    duration: PLANS_DURATIONS
): string => {
   return planName === PLAN_NAMES.PRO
    ? (duration === PLANS_DURATIONS.MONTHLY_PLAN_DURATION ? PRO_MONTHLY_PLAN : PRO_QUARTERLY_PLAN)
    : (duration === PLANS_DURATIONS.MONTHLY_PLAN_DURATION ? STARTER_MONTHLY_PLAN : STARTER_QUARTERLY_PLAN);
}