import React, { FC, ReactElement } from 'react';
import styles from './styles.module.scss';

import { HeaderItemWithSorting } from 'components';

import { ITableHeaderWithSortingProps } from 'types/interfaces';

export const TableHeader: FC<ITableHeaderWithSortingProps> = ({ sortedParamName, sortByParam }): ReactElement => (
    <div className={styles.wrapper}>
        <div className={styles.tableHeader}>
            <div className={styles.headerItems}>
                <div className={styles.tableColumn}>
                    <div>
                        <span>Token Holder</span>
                    </div>
                </div>
                <div className={styles.tokenPercentageColumn}>
                    <HeaderItemWithSorting
                        sortByParam={sortByParam}
                        name="Token Holdings (%)"
                        sortedParamName={sortedParamName}
                        paramName={'tokenHolding'}
                    />
                </div>
                <div className={styles.tableColumn}>
                    <HeaderItemWithSorting
                        sortByParam={sortByParam}
                        name="Votes"
                        sortedParamName={sortedParamName}
                        paramName={'votesCount'}
                    />
                </div>
                <div className={styles.tokenProposalColumn}>
                    <HeaderItemWithSorting
                        sortByParam={sortByParam}
                        name="Proposals"
                        sortedParamName={sortedParamName}
                        paramName={'proposalsCount'}
                    />
                </div>
            </div>
        </div>
    </div>
);
