export const BANNER_IMAGE_URL = 'https://uploads.deepdao.io/images/banner-main-desktop.webp';
export const BANNER_DISCORD_URL = 'https://discord.com/invite/BQBEUS9V9N';
export const ORGANIZATIONS_PAGE_BANNER = 'https://uploads.deepdao.io/images/Main_page_Desktop_1072_192.webp';
export const ORGANIZATIONS_PAGE_MOBILE_BANNER = 'https://uploads.deepdao.io/images/Main_page_Mobile_596_168.webp';
export const USER_PAGE_BANNER = 'https://uploads.deepdao.io/images/Profile_page_300_137.webp';
export const TOOLS_FEED_PAGES_BANNER = 'https://uploads.deepdao.io/images/Tools_Feed_desktop.webp';
export const TOOLS_FEED_PAGES_MOBILE_BANNER = 'https://uploads.deepdao.io/images/Tools_Feed_mobile.webp';

export const ORGANIZATION_TABS_BANNER = 'https://uploads.deepdao.io/DeTrea-web.webp';
export const ORGANIZATION_TABS_MOBILE_BANNER = 'https://uploads.deepdao.io/DeTrea-Mobile.webp';
export const ORGANIZATION_TABS_BANNER_NAVIGATION_LINK =
    'https://adamvault.com/multisig-is-not-safe-whats-the-best-alternative/?utm_source=DeepDAO&utm_medium=banner&utm_id=DeepDAOJan2023';

export const ORGANIZATIONS_BANNER_NAVIGATION_LINK =
    'https://daolens.typeform.com/to/SeMFE3zr?typeform-source=www.daolens.com';
export const USER_BANNER_NAVIGATION_LINK = 'https://daolens.typeform.com/to/SeMFE3zr?typeform-source=www.daolens.com';
export const TOOLS_FEED_PAGES_NAVIGATION_LINK =
    'https://daolens.typeform.com/to/SeMFE3zr?typeform-source=www.daolens.com';

export const BULLA_NETWORK_BANNER = 'https://uploads.deepdao.io/images/bulla_network_desktop1072-192.webp';
export const BULLA_NETWORK_BANNER_MOBILE = 'https://uploads.deepdao.io/images/bulla_network_mobile596-168.webp';
export const BULLA_NETWORK_LINK = 'https://bulla.network/';

export const MAIN_PAGES_BANNER = 'https://uploads.deepdao.io/images/DeepDAO+Pro_Site_V2.png';
export const MAIN_PAGES_BANNER_MOBILE = 'https://uploads.deepdao.io/images/DeepDaoProMobileSite.png';
export const MAIN_PAGES_BANNER_LINK = 'mailto:info@deepdao.io?subject=Know Your Voters';
