import { Reducer } from '@reduxjs/toolkit';

import { PremiumPlansActions } from 'store/actions';

import type { IPremiumPlanActions, IPaypalPlan, IPremiumPlansState } from 'types/interfaces';

const defaultState: IPremiumPlansState = {
    loading: false,
    paypalPlans: [],
    paypalPlan: {} as IPaypalPlan,
};

export const premiumPlansReducer: Reducer<IPremiumPlansState, IPremiumPlanActions> = (
    state: IPremiumPlansState = defaultState,
    action: IPremiumPlanActions,
): IPremiumPlansState => {
    const { type, payload } = <IPremiumPlanActions>action;

    switch (type) {
        case PremiumPlansActions.FETCH_PAYPAL_PLANS: {
            return {
                ...state,
                loading: true,
            };
        }

        case PremiumPlansActions.FETCH_PAYPAL_PLANS_SUCCESS: {
            return {
                ...state,
                loading: false,
                paypalPlans: payload as IPaypalPlan[],
            };
        }

        case PremiumPlansActions.FETCH_PAYPAL_PLANS_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        case PremiumPlansActions.GET_PAYPAL_PLAN: {
            return {
                ...state,
                loading: true,
            };
        }

        case PremiumPlansActions.GET_PAYPAL_PLAN_SUCCESS: {
            return {
                ...state,
                loading: false,
                paypalPlan: payload as IPaypalPlan,
            };
        }

        case PremiumPlansActions.GET_PAYPAL_PLAN_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }

        default: {
            return state;
        }
    }
};
