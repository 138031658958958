import {
    ADDRESS,
    DEEPDAO_ACCESS_TOKEN,
    WEB3_CONNECTION,
    DEEPDAO_REFRESH_ACCESS_TOKEN,
    WEB3_CONNECT_CACHED_PROVIDER,
    WALLET_CONNECT,
    FORTMATIC,
    PREMIUM_USER_TOKEN,
    PREMIUM_USER_EMAIL,
    PREMIUM_USER_REFRESH_TOKEN,
} from './constants';

export const setWeb3ConnectionInStorage = (): void => {
    localStorage.setItem(WEB3_CONNECTION, JSON.stringify(new Date().getTime()));
};

export const getWeb3ConnectionFromStorage = (): string | null => {
    return JSON.parse(localStorage.getItem(WEB3_CONNECTION) || 'null');
};

export const removeWeb3ConnectionFromStorage = (): void => {
    localStorage.removeItem(WEB3_CONNECTION);
};

export const setDeepDAOTokenInStorage = (token: string): void => {
    localStorage.setItem(DEEPDAO_ACCESS_TOKEN, JSON.stringify(token));
};

export const getDeepDAOTokenFromStorage = (): string | null => {
    return JSON.parse(localStorage.getItem(DEEPDAO_ACCESS_TOKEN) || 'null');
};

export const removeDeepDAOTokenFromStorage = (): void => {
    localStorage.removeItem(DEEPDAO_ACCESS_TOKEN);
};

export const removeWalletConnectFromStorage = (): void => {
    localStorage.removeItem(WALLET_CONNECT);
};

export const removeFortmaticFromStorage = (): void => {
    localStorage.removeItem(FORTMATIC);
};

export const setDeepDAORefreshTokenInStorage = (refreshToken: string): void => {
    localStorage.setItem(DEEPDAO_REFRESH_ACCESS_TOKEN, JSON.stringify(refreshToken));
};

export const getDeepDAORefreshTokenFromStorage = (): string | null => {
    return JSON.parse(localStorage.getItem(DEEPDAO_REFRESH_ACCESS_TOKEN) || 'null');
};

export const removeDeepDAORefreshTokenFromStorage = (): void => {
    localStorage.removeItem(DEEPDAO_REFRESH_ACCESS_TOKEN);
};

export const getCachedWalletProviderFromStorage = (): string | null => {
    return JSON.parse(localStorage.getItem(WEB3_CONNECT_CACHED_PROVIDER) || 'null');
};

export const removeCachedWalletProviderFromStorage = (): void => {
    localStorage.removeItem(WEB3_CONNECT_CACHED_PROVIDER);
};

export const setAddressInStorage = (address: string): void => {
    localStorage.setItem(ADDRESS, JSON.stringify(address));
};

export const getAddressFromStorage = (): string | null => {
    return JSON.parse(localStorage.getItem(ADDRESS) || 'null');
};

export const removeAddressFromStorage = (): void => {
    localStorage.removeItem(ADDRESS);
};

export const getPremiumUserTokenFromStorage = (): string | null => {
    return JSON.parse(localStorage.getItem(PREMIUM_USER_TOKEN) || 'null');
};

export const setPremiumUserTokenInStorage = (token: string): void => {
    localStorage.setItem(PREMIUM_USER_TOKEN, JSON.stringify(token));
};

export const removePremiumUserTokenFromStorage = (): void => {
    localStorage.removeItem(PREMIUM_USER_TOKEN);
};

export const getPremiumUserRefreshTokenFromStorage = (): string | null => {
    return JSON.parse(localStorage.getItem(PREMIUM_USER_REFRESH_TOKEN) || 'null');
};

export const setPremiumUserRefreshTokenInStorage = (refreshToken: string): void => {
    localStorage.setItem(PREMIUM_USER_REFRESH_TOKEN, JSON.stringify(refreshToken));
};

export const removePremiumUserRefreshTokenFromStorage = (): void => {
    localStorage.removeItem(PREMIUM_USER_REFRESH_TOKEN);
};

export const getPremiumUserEmailFromStorage = (): string | null => {
    return JSON.parse(localStorage.getItem(PREMIUM_USER_EMAIL) || 'null');
};

export const setPremiumUserEmailInStorage = (email: string): void => {
    localStorage.setItem(PREMIUM_USER_EMAIL, JSON.stringify(email));
};

export const removePremiumUserEmailFromStorage = (): void => {
    localStorage.removeItem(PREMIUM_USER_EMAIL);
};
