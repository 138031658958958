import React, { FC, ReactElement, useCallback, useState, useEffect, useMemo } from 'react';

import { Link, useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import { isEmpty } from 'lodash';

import styles from './styles.module.scss';

import { ConfirmationModal, Loader, SubscriptionInfo } from 'components';

import { BACKEND_PLAN_NAMES, plansDetailsList } from 'constants/plansItems';

import { useAppDispatch, useAppSelector } from 'store';
import { PremiumSubscriptionActions } from 'store/actions';

import { useHistoryPush } from 'hooks';

import { IPremiumPlanDetails } from 'types/interfaces';
import { planNameFromBackendToFrontend } from 'helpers/planHelpers';

export const CancelPremiumSubscriptionPage: FC = (): ReactElement => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const { navigateToProducts, navigateToAccountSettings } = useHistoryPush();
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);

    const { cancelSubscriptionError, premiumUserData, premiumUserDataLoading } = useAppSelector(
        ({ premiumSubscription }) => premiumSubscription
    );
    useEffect(() => {
        if (isEmpty(premiumUserData)) {
            dispatch(PremiumSubscriptionActions.getPremiumUserData.request());
        }
    }, [dispatch, premiumUserData]);

    const currentSubscription = useMemo(() => {
        if (!premiumUserData || !premiumUserData.userSubscriptions) return null;

        const subscription = premiumUserData.userSubscriptions.find(({ subscriptionId }) => id === subscriptionId);
        if (!subscription) {
            navigateToProducts(); 
        }
        return subscription;
    }, [premiumUserData]);

    const currentPlanDetails = useMemo(() => 
        plansDetailsList.find(({ planName }: IPremiumPlanDetails) => 
            planName === planNameFromBackendToFrontend(currentSubscription?.planName as BACKEND_PLAN_NAMES)
        ), 
    [currentSubscription]);

    const onConfirm = useCallback(() => {
        dispatch(
            PremiumSubscriptionActions.cancelPremiumSubscription.request({
                subscriptionId: id,
                navigate: navigateToAccountSettings,
                paymentProvider: currentSubscription?.paymentProvider,
            }),
        );
        setConfirmationModalOpen(false);
    }, [confirmationModalOpen, history]);

    return (
        <> 
            {premiumUserDataLoading ? (
                <Loader />
            ) : (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <Link to="/premium_account_settings" className={styles.link}>
                            <ArrowBackIos className={styles.arrowIcon} />

                            <span className={styles.text}>Settings</span>
                        </Link>
                        <h1 className={styles.title}>
                            Current Plan: <span>{currentPlanDetails?.planName}</span>
                        </h1>

                        <div className={styles.card}>
                            <h5 className={styles.headingText}>Your current benefits:</h5>
                            <div className={styles.benefitsBlock}>
                                {(currentPlanDetails?.planInfo ?? []).map(({ id, text, linkItems }) => (
                                    <SubscriptionInfo id={id} key={id} title={text} linkItems={linkItems} />
                                ))}
                            </div>
                            <div className={styles.optionsBlock}>
                                <Button
                                    className={styles.cancelSubscriptionButton}
                                    onClick={() => setConfirmationModalOpen(true)}
                                >
                                    cancel subscription
                                </Button>
                                {cancelSubscriptionError && (
                                    <h6 className={styles.errorText}>{cancelSubscriptionError}</h6>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {confirmationModalOpen && (
                <ConfirmationModal
                    onClose={() => setConfirmationModalOpen(false)}
                    confirmationModalOpen={confirmationModalOpen}
                    textInfo={{
                        title: 'Are you sure you want to cancel this subscription?',
                        confirmationText: 'Confirm',
                        cancelText: 'Cancel',
                    }}
                    onConfirm={onConfirm}
                />
            )}
        </>
    );
};
