import React, { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import { PremiumSubscription } from 'store/apis';
import { TURNSTILE_SECRET } from 'constants/apis';

interface TProps {
    handleSuccess: () => void;
    handleClose(): void;
}
const TurnstileModal: FC<TProps> = ({ handleSuccess, handleClose }) => {
    const callback = async (token: string) => {
        try {
            const { data } = await PremiumSubscription.verifyTurnstileToken({
                response: token,
                secret: TURNSTILE_SECRET,
            });
            if (data.success) {
                handleSuccess();
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        window.turnstile.render('#turnstile-widget', {
            sitekey: '0x4AAAAAABCbL40WbF2S1vNL',
            callback,
        });
    }, []);

    return (
        <div className={styles.overlay} onClick={handleClose}>
            <div id="turnstile-widget" />
        </div>
    );
};

export default TurnstileModal;
