import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { loadScript } from '@paypal/paypal-js';
import { Link } from 'react-router-dom';

import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import classNames from 'classnames';

import styles from './styles.module.scss';

import { FormRadioButtons, Loader } from 'components';

import {
    plansDurations,
    PLANS_DURATIONS,
    PLANS_PAGES_INDEXES,
    detailedPlansPricings,
    PLAN_NAMES,
} from 'constants/plansItems';

import { getTotalPlanPrice } from './utils';

import { IDetailedPlan, ISelectOptions, IStartPremiumPlanProps } from 'types/interfaces';
import { PAYPAL_CLIENT_ID } from 'constants/apis';
import { getCorrectPaypalId } from 'components/PlansPageBody/utils';

export const StartPremiumPlan: FC<IStartPremiumPlanProps> = ({
    formik,
    changePlansPage,
    paypalSubscription,
    paypalSubscriptionError,

    isUpgradingType,
}: IStartPremiumPlanProps): ReactElement => {
    const [loader, setLoader] = useState<boolean>(false);

    const changeDuration = useCallback(
        (option: ISelectOptions) => {
            formik.setFieldValue('planInfo.duration', option);
            formik.setFieldValue('planInfo.price', getTotalPlanPrice(formik.values.planInfo.planName, option));
        },
        [formik],
    );

    const durationRef = useRef(formik.values.planInfo.duration.value);

    useEffect(() => {
        durationRef.current = formik.values.planInfo.duration.value;
    }, [formik.values.planInfo.duration]);

    useEffect(() => {
        (async () => {
            let paypal;
            try {
                paypal = await loadScript({
                    clientId: PAYPAL_CLIENT_ID,
                    vault: true,
                    intent: 'subscription',
                });
            } catch (error) {
                console.error('failed to load the PayPal JS SDK script', error);
            }

            if (paypal) {
                try {
                    await paypal
                        .Buttons?.({
                            createSubscription: function (data, actions) {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                const plan_id = getCorrectPaypalId(formik.values.planInfo.planName as PLAN_NAMES, 
                                    durationRef.current as PLANS_DURATIONS);
                                return actions.subscription.create({
                                    plan_id,
                                });
                            },
                            onApprove: async function (responseData, actions) {
                                if (responseData.subscriptionID) {
                                    setLoader(true);
                                    paypalSubscription(responseData.subscriptionID);
                                }
                            },
                        })
                        .render('#paypal-button-container');
                } catch (error) {
                    console.error('failed to render the PayPal Buttons', error);
                }
            }
        })();
    }, []);

    return (
        <div className={styles.container}>
            {isUpgradingType ? (
                <div className={styles.head}>
                    <div className={styles.navigationBackBlock}>
                        <Link to="/premium_account_settings" className={styles.accountSettingsLink}>
                            <ArrowBackIos className={styles.arrowIcon} />
                            <span className={styles.text}>Settings</span>
                        </Link>
                    </div>
                    <h1 className={styles.title}>Upgrade your plan</h1>
                </div>
            ) : (
                <>
                    <h1 className={styles.title}>Start your Subscription</h1>
                    <h6 className={styles.description}>Enter the details below to start your subscription</h6>
                </>
            )}
            {loader && (
                <div className={styles.loaderBox}>
                    <Loader size={40} className={styles.membersLoader} />
                </div>
            )}
            {!loader && (<div className={styles.cardsWrapper}>
                <div className={styles.card}>
                    {!isUpgradingType && !loader &&(
                        <>
                            <div className={styles.cardHeader}>
                                <h5 className={styles.headText}>{formik.values.userInfo.fullName}</h5>
                            </div>
                            <div className={styles.userDetails}>
                                <p>{formik.values.userInfo.email}</p>
                                {formik.values.userInfo.phoneNumber && <p>{formik.values.userInfo.phoneNumber}</p>}
                                {(formik.values.userInfo.countryCode ||
                                    formik.values.userInfo.country ||
                                    formik.values.userInfo.postalCode) && (
                                    <h6 className={styles.locationInfo}>
                                        {formik.values.userInfo.countryCode && (
                                            <span>{formik.values.userInfo.countryCode}</span>
                                        )}
                                        {formik.values.userInfo.country && (
                                            <span>{formik.values.userInfo.country}</span>
                                        )}
                                        {formik.values.userInfo.postalCode && (
                                            <span>{formik.values.userInfo.postalCode}</span>
                                        )}
                                    </h6>
                                )}
                            </div>
                        </>
                    )}
                    <div
                        className={classNames(styles.block, {
                            [styles.removeMarginTop]: isUpgradingType,
                        })}
                    >
                        <h5 className={styles.headText}>Billing Cycle</h5>
                        <h6 className={styles.descriptionText}>Renews automatically, can be canceled anytime.</h6>
                        <div className={styles.radioButtonsContent}>
                            <div className={styles.radioWrapper}>
                                <FormRadioButtons
                                    options={plansDurations}
                                    selectedOption={formik.values.planInfo.duration}
                                    onChange={changeDuration}
                                    className={styles.periodRadioInputsWrapper}
                                />
                            </div>
                            <div className={styles.pricingsBlock}>
                                {detailedPlansPricings[
                                    formik.values.planInfo.planName.toUpperCase() as PLAN_NAMES
                                ]?.map((info: IDetailedPlan) => (
                                    <p
                                        key={info.id}
                                        className={classNames(styles.price, {
                                            [styles.bold]: info.duration === formik.values.planInfo.duration.value,
                                        })}
                                    >
                                        ${info.pricing} USD
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div id="paypal-button-container"></div>
                </div>

                <div className={styles.card}>
                    <div className={styles.cardHeader}>
                        <h2 className={styles.planName}>{formik.values.planInfo.title}</h2>
                        <div
                            className={styles.editOption}
                            onClick={() => changePlansPage(PLANS_PAGES_INDEXES.PLANS_INFO)}
                        >
                            change
                        </div>
                    </div>
                    <p className={styles.sumInfo}>
                        ${getTotalPlanPrice(formik.values.planInfo.planName, formik.values.planInfo.duration)} USD,
                        charged{' '}
                        {formik.values.planInfo.duration.value === PLANS_DURATIONS.MONTHLY_PLAN_DURATION
                            ? 'monthly'
                            : 'every 3 month'}
                    </p>
                    <div className={styles.totalSumInfo}>
                        <h5 className={styles.headText}>Total</h5>
                        <h5 className={styles.headText}>
                            ${getTotalPlanPrice(formik.values.planInfo.planName, formik.values.planInfo.duration)} USD
                        </h5>
                    </div>
                    {paypalSubscriptionError && <h6 className={styles.errorText}>{paypalSubscriptionError}</h6>}
                </div>
            </div>)}
        </div>
    );
};
