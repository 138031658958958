import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';
import { LinkUtils } from 'utils';
import { defaultUserPlaceholderImage } from 'assets';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { Link } from 'react-router-dom';
import { IOrganizationHoldingToken } from 'types/interfaces';
import { FormatUtils } from 'utils';

export const RowItems: FC<IOrganizationHoldingToken> = ({
    organization,
    totalTokenUsd,
    totalTokenValue,
}): ReactElement => {
    return (
        <div className={styles.tableRow}>
            <div className={styles.organizationColumn}>
                <ImageLoader
                    src={organization.logo || defaultUserPlaceholderImage}
                    className={styles.avatar}
                    imageType={IMAGE_TYPES.USER_IMAGE}
                />
                <div className={styles.holderInfo}>
                    <TooltipWrapper title={organization.title}>
                        <Link
                            to={LinkUtils.createDynamicLink(organization.id, 'organization')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h5 className={styles.holderName}>{organization.title}</h5>
                        </Link>
                    </TooltipWrapper>
                </div>
            </div>
            <div className={styles.tableColumn}>
                <h5 className={styles.protocolType}>{FormatUtils.formatNumberShort(totalTokenValue, 1)}</h5>
            </div>
            <div className={styles.tableColumn}>
                <h5 className={styles.protocolType}>{FormatUtils.formatNumberShort(totalTokenUsd, 1)}</h5>
            </div>
        </div>
    );
};
