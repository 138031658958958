import { BACKEND_PLAN_NAMES, PLAN_NAMES } from "constants/plansItems";

export const planNameFromBackendToFrontend = (planName:BACKEND_PLAN_NAMES) => {
    switch(planName)  {
        case BACKEND_PLAN_NAMES.STARTER:
            return PLAN_NAMES.STARTER;
        
        case BACKEND_PLAN_NAMES.PRO:
            return PLAN_NAMES.PRO;
            
        default:
            return PLAN_NAMES.BASIC;
    }
}
