import React, { FC, ReactElement } from 'react';

import styles from './styles.module.scss';

import { TooltipWrapper, ImageLoader } from 'components';

import { defaultUserPlaceholderImage } from 'assets';

import { IMAGE_TYPES } from 'constants/imageTypes';
import { Link } from 'react-router-dom';
import { LinkUtils } from 'utils';
import { IOrganizationTopShareholder } from 'types/interfaces';

export const RowItems: FC<IOrganizationTopShareholder> = ({
    name,
    avatar,
    address,
    tokenSharesPercentage,
    votesCount,
    proposalsCount,
}): ReactElement => {
    const tokenHolder = name || address;
    return (
        <div className={styles.tableRow}>
            <div className={styles.tableColumn}>
                <ImageLoader
                    src={avatar || defaultUserPlaceholderImage}
                    className={styles.avatar}
                    imageType={IMAGE_TYPES.USER_IMAGE}
                />
                <div className={styles.holderInfo}>
                    <TooltipWrapper title={tokenHolder}>
                        <Link
                            to={LinkUtils.createDynamicLink(address, 'user')}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h5 className={styles.holderName}>{tokenHolder}</h5>
                        </Link>
                    </TooltipWrapper>
                </div>
            </div>
            <div className={styles.tokenPercentageColumn}>
                <h5 className={styles.protocolType}>{tokenSharesPercentage?.toFixed(2)}</h5>
            </div>
            <div className={styles.tableColumn}>
                <h5 className={styles.protocolType}>{votesCount}</h5>
            </div>
            <div className={styles.tokenProposalColumn}>
                <h5 className={styles.protocolType}>{proposalsCount}</h5>
            </div>
        </div>
    );
};
