export const BASE_URL = 'https://deepdao-server.deepdao.io/';
export const DAO_LOGOS_BUCKET_BASE_URL = 'https://uploads.deepdao.io/assets/dao/logo';
export const API_KEY = 'ksdf3ksa-937slj3';
export const BACKOFICCE_URL = 'https://backoffice-server.deepdao.io/';
export const IPFS_LOGO_URL = 'ipfs://';
export const IPFS_MAIN_URL = 'https://ipfs.io/ipfs/';
export const WEBSOCKET_URL = 'wss://deepdao-server-ws.deepdao.io';
export const STARTER_MONTHLY_PLAN = 'P-8PH74150Y0742553EM7VIFEA';
export const PRO_MONTHLY_PLAN = 'P-77Y22649SS2138829M7VIGHQ';
export const PRO_QUARTERLY_PLAN = 'P-2CV35441389908635M7VIGUI';
export const STARTER_QUARTERLY_PLAN ='P-7XV68055XK635863WM7VIFQY';
export const PAYPAL_CLIENT_ID = 'AeJdzekDnIAYMqOcUJuf6oqu6wXPskWraSWREMQdbmvYIQiaHYi-Hp9GG1GLu-67TBoUA7doxLLkaEk6';
export const TURNSTILE_SECRET = '0x4AAAAAABCbL9SEaRqw1MO5jIkJAdy6UnU';

export const DEEP_DAO_API = 'https://api.deepdao.io';
export const DEEP_DAO_API_PRO = 'https://api.deepdao.io/pro';